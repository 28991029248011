import { alpha } from '@mui/material/styles'

import { palette } from './palette'

interface CustomShadowOptions {
  // Ready for production
  z1: string
  z8: string
  z16: string
  primary: string
  // To be defined
  secondary: string
  info: string
  success: string
  warning: string
  error: string
}

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    customShadows: CustomShadowOptions
  }
  interface ThemeOptions {
    customShadows?: CustomShadowOptions
  }
}

const createCustomShadow = () => {
  return {
    // Ready for production
    z1: '0px 2px 2px 0px rgb(30 30 30 / 10%), 0px 1px 5px 0px rgb(30 30 30 / 10%)',
    z8: '0px ​4px 12px 0px rgb(30 30 30 / 10%)',
    z16: '0px 3px 10px 6px rgb(30 30 30 / 6%)',
    primary: '0px 2px 6px rgba(41, 81, 223, 0.3)',
    // To be defined
    info: `0 8px 16px 0 ${alpha(palette.info.main, 0.24)}`,
    secondary: `0 8px 16px 0 ${alpha(palette.secondary.main, 0.24)}`,
    success: `0 8px 16px 0 ${alpha(palette.success.main, 0.24)}`,
    warning: `0 8px 16px 0 ${alpha(palette.warning.main, 0.24)}`,
    error: `0 8px 16px 0 ${alpha(palette.error.main, 0.24)}`,
  }
}
export const customShadows = {
  light: createCustomShadow(),
  dark: createCustomShadow(),
}
