import { formatDuration, intervalToDuration } from 'date-fns'

import { computeActivityDuration } from './computeActivityDuration'
import { getDateFnsLocale } from './getDateFnsLocale'
import { i18n } from './i18nConfig'

export const getHumanReadableActivityDuration = ({
  isDurationEnabled,
  activityDurationInSeconds,
  accommodationTimePercentage = 0,
}: {
  isDurationEnabled: boolean
  activityDurationInSeconds: number
  accommodationTimePercentage: number
}) => {
  if (!isDurationEnabled) {
    return i18n.t('global.unlimitedTime', 'Unlimited time')
  }

  const durationWithAccommodationIfNecessary = computeActivityDuration({
    activityDurationInSeconds,
    accommodationTimePercentage,
  })
  const activityDuration = intervalToDuration({
    start: 0,
    end: durationWithAccommodationIfNecessary * 1000,
  })

  const humanReadableDuration = formatDuration(activityDuration, { locale: getDateFnsLocale() })
  return humanReadableDuration
}
