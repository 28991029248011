import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const FlagPin: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.52689 13.644C9.60059 14.0516 9.32991 14.4418 8.92231 14.5155C7.3928 14.7921 6.14141 15.243 5.29416 15.7745C4.42102 16.3223 4.13477 16.8455 4.13477 17.2351C4.13477 17.5005 4.25803 17.8169 4.61381 18.174C4.97274 18.5342 5.52969 18.8927 6.27744 19.2098C7.77009 19.8428 9.88353 20.2515 12.2547 20.2515C14.6264 20.2515 16.7373 19.8428 18.2273 19.21C18.9737 18.893 19.5293 18.5346 19.8873 18.1745C20.2421 17.8177 20.3653 17.5011 20.3653 17.2351C20.3653 16.8459 20.0771 16.32 19.2019 15.7705C18.3535 15.2379 17.1025 14.7871 15.5797 14.5158C15.1719 14.4432 14.9002 14.0537 14.9729 13.6459C15.0455 13.2381 15.435 12.9664 15.8428 13.0391C17.4863 13.3319 18.9374 13.8333 19.9994 14.5001C21.0347 15.1501 21.8653 16.0709 21.8653 17.2351C21.8653 18.0117 21.4928 18.6873 20.951 19.2322C20.4124 19.7739 19.6706 20.2267 18.8136 20.5906C17.0969 21.3197 14.7776 21.7515 12.2547 21.7515C9.73229 21.7515 7.41078 21.3197 5.69186 20.5908C4.83382 20.227 4.09082 19.7742 3.55126 19.2327C3.00855 18.6881 2.63477 18.0124 2.63477 17.2351C2.63477 16.0713 3.46227 15.153 4.49703 14.5038C5.55768 13.8385 7.00834 13.3373 8.65541 13.0394C9.06301 12.9657 9.45319 13.2364 9.52689 13.644Z"
        fill="currentColor"
      />
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8188 2.38635C12.0187 2.24592 12.2744 2.21161 12.5042 2.2944L18.9901 4.63102C19.3641 4.76576 19.568 5.16891 19.4549 5.55001L18.4163 9.0498C18.3234 9.36309 18.0382 9.58012 17.7115 9.58629L13 9.67532V16.7099C13 17.1241 12.6642 17.4599 12.25 17.4599C11.8358 17.4599 11.5 17.1241 11.5 16.7099V8.94112V8.93703V3C11.5 2.75574 11.619 2.52678 11.8188 2.38635ZM13 8.17505L17.1345 8.09693L17.8154 5.8022L13 4.06739V8.17505Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
