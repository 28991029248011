import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const ShieldProfile: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M19.5737 12.6694C19.5737 18.7826 12.2497 21 12.2497 21C12.2497 21 4.92668 18.7836 4.92668 12.6694C4.92668 6.55524 4.6597 6.07751 5.2477 5.48984C5.8367 4.90119 11.2897 3 12.2497 3C13.2107 3 18.6627 4.89632 19.2517 5.48984C19.8397 6.08238 19.5737 6.55622 19.5737 12.6694Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M7.80566 18.4834C7.98266 17.1125 9.20164 15.5898 12.2296 15.5898C13.5672 15.5898 14.5515 15.8822 15.25 16.3273"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M12.2328 7.65234C13.8058 7.65234 15.0818 8.9279 15.0818 10.5012C15.0818 12.0745 13.8058 13.3501 12.2328 13.3501C10.6598 13.3501 9.38379 12.0745 9.38379 10.5012C9.38379 10.0544 9.48666 9.63174 9.67 9.25541"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
