import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Bold: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Bold type"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0582 17.18C14.5842 17.701 13.9102 18 13.2082 18H8.29224V13H13.0582C14.4162 13 15.5752 13.992 15.6962 15.255C15.7642 15.969 15.5372 16.653 15.0582 17.18ZM8.29224 6H11.8042C13.1622 6 14.3222 6.991 14.4422 8.255C14.5102 8.969 14.2842 9.653 13.8042 10.18C13.3302 10.701 12.6562 11 11.9542 11H8.29224V6ZM15.2482 11.558C15.2592 11.546 15.2732 11.538 15.2842 11.526C16.1352 10.589 16.5542 9.328 16.4332 8.066C16.2162 5.786 14.1832 4 11.8042 4H7.29224C6.74024 4 6.29224 4.448 6.29224 5V19C6.29224 19.553 6.74024 20 7.29224 20H13.2082C14.4722 20 15.6862 19.463 16.5382 18.526C17.3892 17.589 17.8082 16.328 17.6872 15.065C17.5412 13.542 16.5762 12.254 15.2482 11.558Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
