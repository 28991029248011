import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Microphone: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.252 21.5902C12.053 21.5902 11.8623 21.5112 11.7216 21.3706C11.581 21.2299 11.502 21.0392 11.502 20.8402V17.9902C11.502 17.7913 11.581 17.6006 11.7216 17.4599C11.8623 17.3193 12.053 17.2402 12.252 17.2402C12.4509 17.2402 12.6416 17.3193 12.7823 17.4599C12.9229 17.6006 13.002 17.7913 13.002 17.9902V20.8383C13.0022 20.9369 12.983 21.0347 12.9454 21.1259C12.9079 21.2171 12.8527 21.3 12.783 21.3699C12.7133 21.4397 12.6306 21.4951 12.5394 21.533C12.4483 21.5708 12.3506 21.5902 12.252 21.5902V21.5902Z"
        fill="currentColor"
      />
      <path
        d="M12.2539 18.743C11.2064 18.741 10.1696 18.5327 9.20264 18.1299C8.23569 17.7271 7.35756 17.1377 6.61845 16.3954C5.87933 15.6531 5.29372 14.7725 4.89504 13.8038C4.49637 12.8352 4.29245 11.7974 4.29494 10.7499C4.29508 10.6516 4.31459 10.5542 4.35235 10.4634C4.39011 10.3726 4.44538 10.2901 4.51502 10.2206C4.58467 10.1512 4.66731 10.0961 4.75823 10.0586C4.84916 10.021 4.94658 10.0018 5.04494 10.002V10.002C5.14344 10.0022 5.24092 10.0219 5.33181 10.0598C5.4227 10.0977 5.50523 10.1532 5.57468 10.223C5.64414 10.2929 5.69918 10.3757 5.73663 10.4668C5.77407 10.5579 5.79321 10.6554 5.79295 10.7539C5.79295 11.6019 5.95995 12.4415 6.28445 13.2249C6.60894 14.0083 7.08456 14.7201 7.68415 15.3197C8.28374 15.9193 8.99555 16.3949 9.77895 16.7194C10.5624 17.0439 11.402 17.2109 12.2499 17.2109C13.0979 17.2109 13.9375 17.0439 14.7209 16.7194C15.5043 16.3949 16.2161 15.9193 16.8157 15.3197C17.4153 14.7201 17.8909 14.0083 18.2154 13.2249C18.5399 12.4415 18.7069 11.6019 18.7069 10.7539C18.7068 10.6554 18.7261 10.5579 18.7636 10.4669C18.8012 10.3758 18.8564 10.2931 18.9259 10.2233C18.9955 10.1536 19.0781 10.0982 19.169 10.0604C19.26 10.0226 19.3574 10.0031 19.4559 10.0029C19.5544 10.0028 19.6519 10.0221 19.7429 10.0597C19.8339 10.0972 19.9166 10.1524 19.9862 10.222C20.0559 10.2915 20.1111 10.3742 20.1488 10.4651C20.1865 10.556 20.2059 10.6535 20.2059 10.752C20.2078 12.8651 19.3719 14.8928 17.8813 16.3907C16.3907 17.8886 14.3671 18.7345 12.2539 18.743V18.743Z"
        fill="currentColor"
      />
      <path
        d="M12.2542 15.1469C11.674 15.1458 11.0998 15.0304 10.5642 14.8073C10.0286 14.5843 9.54226 14.2578 9.1329 13.8467C8.72354 13.4356 8.39921 12.9478 8.17843 12.4113C7.95766 11.8748 7.84476 11.3 7.8462 10.7198V6.49985C7.8374 5.9152 7.94495 5.33463 8.16259 4.79193C8.38024 4.24923 8.70365 3.75524 9.11398 3.33868C9.52431 2.92212 10.0134 2.5913 10.5527 2.36551C11.0921 2.13972 11.671 2.02344 12.2557 2.02344C12.8404 2.02344 13.4193 2.13972 13.9587 2.36551C14.498 2.5913 14.9871 2.92212 15.3974 3.33868C15.8078 3.75524 16.1312 4.24923 16.3488 4.79193C16.5665 5.33463 16.674 5.9152 16.6652 6.49985V10.7219C16.6664 11.3018 16.5533 11.8762 16.3325 12.4125C16.1117 12.9487 15.7874 13.4362 15.3782 13.8471C14.9689 14.258 14.4828 14.5842 13.9475 14.8073C13.4121 15.0303 12.8381 15.1457 12.2582 15.1469H12.2542ZM12.2542 3.58984C11.4828 3.5909 10.7434 3.89786 10.1981 4.44339C9.65271 4.98892 9.34601 5.72848 9.34521 6.49985V10.7219C9.33804 11.1085 9.40799 11.4927 9.55099 11.8519C9.69399 12.2112 9.90715 12.5383 10.178 12.8143C10.4489 13.0903 10.7721 13.3095 11.1287 13.4591C11.4852 13.6087 11.868 13.6858 12.2547 13.6858C12.6414 13.6858 13.0242 13.6087 13.3808 13.4591C13.7373 13.3095 14.0605 13.0903 14.3314 12.8143C14.6023 12.5383 14.8154 12.2112 14.9584 11.8519C15.1014 11.4927 15.1714 11.1085 15.1642 10.7219V6.49985C15.1634 5.72831 14.8566 4.98861 14.311 4.44305C13.7655 3.8975 13.0257 3.59064 12.2542 3.58984Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
