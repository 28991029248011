export const computeActivityDuration = ({
  activityDurationInSeconds,
  accommodationTimePercentage = 0,
}: {
  activityDurationInSeconds: number
  accommodationTimePercentage: number
}) => {
  const durationWithAccommodationIfNecessary =
    activityDurationInSeconds * (1 + accommodationTimePercentage / 100)

  return durationWithAccommodationIfNecessary
}
