import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const WaitingClock: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Clock dash"
      width="24"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4608 14.6061L12.5485 12.4535V8.44434"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.14655 6.09375C5.79048 6.51742 5.47629 6.96203 5.20496 7.43521"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33494 16.3942C5.02457 15.8858 4.75323 15.3326 4.55139 14.748"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0795 20.4355L11.089 20.4346C11.7269 20.5717 12.3876 20.6288 13.0531 20.6107"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.91319 3.50781C9.33719 3.70775 8.78213 3.97052 8.26611 4.29136"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4366 19.3252C16.9187 19.6375 16.3656 19.9088 15.7896 20.1087"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5136 10.2168C21.5983 10.6814 21.6459 11.1441 21.6545 11.604C21.6754 12.279 21.6145 12.9216 21.4764 13.5595L21.4783 13.569C21.2984 14.4878 20.9594 15.3608 20.4939 16.1624C20.233 16.6442 19.9122 17.0983 19.5542 17.5134"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6484 3.00352C13.3139 2.98448 13.9747 3.04256 14.6125 3.18061C15.5322 3.36055 16.4139 3.69758 17.2155 4.16314C17.6982 4.43257 18.1523 4.74485 18.5665 5.10283C19.2691 5.69882 19.8803 6.40716 20.3678 7.2126"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.48433 19.4428C8.01211 19.181 7.55892 18.8678 7.14478 18.5107"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.2237 10.0361C4.0866 10.674 4.02852 11.3348 4.04852 12.0098"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
