import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const MessagesBubble: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="messages bubble">
        <path
          id="Vector"
          d="M9.8904 21.2172C9.83418 21.2173 9.77815 21.211 9.72341 21.1982C9.11349 21.0603 8.51961 20.8592 7.9514 20.5982C7.68568 20.4698 7.40162 20.3834 7.10939 20.3422C6.78978 20.3776 6.47673 20.4577 6.1794 20.5802C5.4694 20.8242 4.49841 21.1582 3.77941 20.4412C3.52464 20.0984 3.37556 19.6886 3.35051 19.2622C3.32546 18.8358 3.42551 18.4114 3.63839 18.0412C3.76055 17.738 3.83967 17.4193 3.8734 17.0942C3.83327 16.79 3.74374 16.4945 3.60839 16.2192C2.85535 14.5615 2.62424 12.7139 2.94573 10.9218C3.26722 9.12971 4.12611 7.47775 5.4084 6.18517C6.23351 5.35698 7.21401 4.69985 8.29366 4.25146C9.37331 3.80308 10.5309 3.57227 11.6999 3.57227C12.869 3.57227 14.0265 3.80308 15.1062 4.25146C16.1858 4.69985 17.1663 5.35698 17.9914 6.18517C18.1236 6.32759 18.1954 6.51577 18.1916 6.71007C18.1878 6.90437 18.1088 7.08962 17.9711 7.22678C17.8334 7.36393 17.6479 7.44229 17.4535 7.44534C17.2592 7.4484 17.0713 7.37592 16.9294 7.24316C16.2435 6.55506 15.4286 6.00909 14.5313 5.63657C13.634 5.26404 12.672 5.07228 11.7004 5.07228C10.7288 5.07228 9.76683 5.26404 8.86953 5.63657C7.97222 6.00909 7.15728 6.55506 6.47139 7.24316C5.40191 8.3242 4.6871 9.70537 4.42217 11.2028C4.15724 12.7002 4.35474 14.2428 4.98839 15.6252C5.21101 16.0852 5.34242 16.5841 5.37538 17.0942C5.34812 17.5844 5.24177 18.0669 5.06041 18.5232C4.94246 18.7986 4.87212 19.0921 4.85241 19.3912C5.14055 19.3486 5.42212 19.2696 5.69039 19.1562C6.14202 18.9739 6.62025 18.8662 7.1064 18.8372C7.60725 18.8686 8.09709 18.9977 8.54839 19.2172C9.03105 19.4423 9.53622 19.6156 10.0554 19.7342C10.2358 19.7744 10.395 19.8798 10.5024 20.0303C10.6098 20.1807 10.6579 20.3655 10.6375 20.5491C10.617 20.7328 10.5295 20.9025 10.3916 21.0256C10.2537 21.1487 10.0752 21.2176 9.8904 21.2172Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M16.4204 21.2145C14.853 21.2163 13.3491 20.5953 12.2395 19.4882C11.1299 18.381 10.5056 16.8785 10.5039 15.311C10.5022 13.7436 11.1232 12.2397 12.2303 11.1302C13.3374 10.0206 14.84 9.39628 16.4074 9.39456C17.1822 9.39227 17.9498 9.54413 18.6653 9.8413C19.3809 10.1385 20.0302 10.575 20.5754 11.1255C21.4264 11.9858 21.9951 13.0849 22.2059 14.2765C22.4167 15.468 22.2596 16.6955 21.7554 17.7956C21.6958 17.9283 21.6518 18.0676 21.6244 18.2106C21.6478 18.3855 21.6928 18.5568 21.7584 18.7205C21.9506 18.9906 22.0406 19.3201 22.0126 19.6503C21.9846 19.9806 21.8404 20.2902 21.6056 20.5241C21.3707 20.758 21.0605 20.9009 20.7302 20.9276C20.3998 20.9542 20.0707 20.8628 19.8014 20.6696C19.6408 20.6046 19.4729 20.5593 19.3014 20.5346C19.1542 20.5677 19.0107 20.6159 18.8734 20.6786C18.1041 21.0323 17.2672 21.2152 16.4204 21.2145ZM16.4074 10.8946C15.8299 10.8929 15.2578 11.006 14.7244 11.2275C14.191 11.449 13.7069 11.7743 13.3004 12.1846C12.5676 12.9158 12.1155 13.8817 12.0233 14.9129C11.9311 15.944 12.2048 16.9748 12.7963 17.8244C13.3878 18.6741 14.2594 19.2886 15.2584 19.5601C16.2575 19.8316 17.3202 19.7429 18.2604 19.3096L18.2934 19.2956C18.6099 19.1437 18.953 19.0551 19.3034 19.0346C19.6421 19.0518 19.9757 19.1251 20.2904 19.2516L20.3634 19.2765L20.3384 19.2015C20.2132 18.8852 20.1412 18.5504 20.1254 18.2106C20.144 17.8613 20.2303 17.5189 20.3794 17.2026C20.7642 16.3776 20.8875 15.4547 20.7329 14.5576C20.5783 13.6605 20.1531 12.8322 19.5144 12.1836C19.1077 11.7736 18.6236 11.4486 18.0903 11.2273C17.5569 11.006 16.9849 10.8929 16.4074 10.8946Z"
          fill="currentColor"
        />
      </g>
    </svg>
  </SvgIcon>
)
