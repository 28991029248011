import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const TitleH4: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="h4"
      width="24"
      height="24"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6587 9.25099C20.975 9.34676 21.1913 9.63831 21.1913 9.96881V15.5717H21.5002C21.9144 15.5717 22.2502 15.9075 22.2502 16.3217C22.2502 16.7359 21.9144 17.0717 21.5002 17.0717H21.1913V18.4394C21.1913 18.8536 20.8556 19.1894 20.4413 19.1894C20.0271 19.1894 19.6913 18.8536 19.6913 18.4394V17.0717H16.2061C15.9295 17.0717 15.6753 16.9195 15.5448 16.6756C15.4143 16.4318 15.4286 16.1358 15.582 15.9057L19.8173 9.55278C20.0006 9.27779 20.3424 9.15522 20.6587 9.25099ZM19.6913 15.5717V12.4459L17.6074 15.5717H19.6913Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 4.98438C3.91421 4.98438 4.25 5.32016 4.25 5.73438V11.3373H11.2206V5.73438C11.2206 5.32016 11.5563 4.98438 11.9706 4.98438C12.3848 4.98438 12.7206 5.32016 12.7206 5.73438V18.4402C12.7206 18.8544 12.3848 19.1902 11.9706 19.1902C11.5563 19.1902 11.2206 18.8544 11.2206 18.4402V12.8373H4.25V18.4402C4.25 18.8544 3.91421 19.1902 3.5 19.1902C3.08579 19.1902 2.75 18.8544 2.75 18.4402V5.73438C2.75 5.32016 3.08579 4.98438 3.5 4.98438Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
