import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const TitleH3: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="h3"
      width="24"
      height="24"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 4.98438C3.91421 4.98438 4.25 5.32016 4.25 5.73438V11.3373H11.2206V5.73438C11.2206 5.32016 11.5563 4.98438 11.9706 4.98438C12.3848 4.98438 12.7206 5.32016 12.7206 5.73438V18.4402C12.7206 18.8544 12.3848 19.1902 11.9706 19.1902C11.5563 19.1902 11.2206 18.8544 11.2206 18.4402V12.8373H4.25V18.4402C4.25 18.8544 3.91421 19.1902 3.5 19.1902C3.08579 19.1902 2.75 18.8544 2.75 18.4402V5.73438C2.75 5.32016 3.08579 4.98438 3.5 4.98438Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5154 12.0856C16.5154 10.5022 17.7988 9.21875 19.3823 9.21875C20.9657 9.21875 22.2492 10.5022 22.2492 12.0856C22.2492 12.9237 21.8895 13.6785 21.3158 14.2033C21.89 14.7275 22.2499 15.4821 22.2499 16.3209C22.2499 17.9059 20.9665 19.1893 19.3815 19.1893C17.7977 19.1893 16.5146 17.9055 16.5146 16.3209C16.5146 15.9067 16.8504 15.5709 17.2646 15.5709C17.6789 15.5709 18.0146 15.9067 18.0146 16.3209C18.0146 17.0778 18.6268 17.6893 19.3815 17.6893C20.1381 17.6893 20.7499 17.0775 20.7499 16.3209C20.7499 15.5659 20.1388 14.954 19.3823 14.954C18.9681 14.954 18.6323 14.6182 18.6323 14.204C18.6323 13.7898 18.9681 13.454 19.3823 13.454C20.1366 13.454 20.7492 12.8414 20.7492 12.0856C20.7492 11.3306 20.1373 10.7188 19.3823 10.7188C18.6272 10.7188 18.0154 11.3306 18.0154 12.0856C18.0154 12.4998 17.6796 12.8356 17.2654 12.8356C16.8512 12.8356 16.5154 12.4998 16.5154 12.0856Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
