export const typography = {
  fontSize: 14,
  fontFamily: "'Inter', sans-serif",
  body1: {
    letterSpacing: 0,
    fontFamily: "'Inter', sans-serif",
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '24px',
  },
  body1Bold: {
    letterSpacing: 0,
    fontFamily: "'Inter', sans-serif",
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '24px',
  },
  body2: {
    letterSpacing: 0,
    fontFamily: "'Inter', sans-serif",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
  },
  body2Bold: {
    letterSpacing: 0,
    fontFamily: "'Inter', sans-serif",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
  },
  body3: {
    letterSpacing: 0,
    fontFamily: "'Inter', sans-serif",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '16px',
  },
  body3Bold: {
    letterSpacing: 0,
    fontFamily: "'Inter', sans-serif",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '16px',
  },
  body4: {
    letterSpacing: 0,
    fontFamily: "'Inter', sans-serif",
    fontSize: 12,
    lineHeight: '16px',
  },
  body4Bold: {
    letterSpacing: 0,
    fontFamily: "'Inter', sans-serif",
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
  },
  body5: {
    letterSpacing: 0,
    fontFamily: "'Inter', sans-serif",
    fontWeight: 400,
    fontSize: 11,
    lineHeight: '16px',
  },
  body5Bold: {
    letterSpacing: 0,
    fontFamily: "'Inter', sans-serif",
    fontWeight: 600,
    fontSize: 11,
    lineHeight: '16px',
  },
  h1: {
    letterSpacing: 0,
    fontSize: 80,
    fontWeight: 600,
    fontFamily: "'Inter', sans-serif",
    lineHeight: '104px',
  },
  h2: {
    letterSpacing: 0,
    fontSize: 56,
    fontWeight: 600,
    fontFamily: "'Inter', sans-serif",
    lineHeight: '64px',
  },
  h3: {
    letterSpacing: 0,
    fontSize: 40,
    fontWeight: 600,
    fontFamily: "'Inter', sans-serif",
    lineHeight: '48px',
  },
  h4: {
    letterSpacing: 0,
    fontSize: 32,
    fontWeight: 600,
    fontFamily: "'Inter', sans-serif",
    lineHeight: '40px',
  },
  h5: {
    letterSpacing: 0,
    fontSize: 20,
    fontWeight: 600,
    fontFamily: "'Inter', sans-serif",
    lineHeight: '24px',
  },
  h6: {
    letterSpacing: 0,
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "'Inter', sans-serif",
    lineHeight: '24px',
  },
  button: {
    fontFamily: "'Inter', sans-serif",
    fontWeight: 500,
    fontSize: 16,
  },
  button2: {
    fontFamily: "'Inter', sans-serif",
    fontWeight: 500,
    fontSize: 14,
  },
  tabNavigation: {
    default: {
      fontSize: 14,
      fontWeight: 400,
    },
    large: {
      fontWeight: 400,
      fontSize: 18,
    },
    small: {
      fontWeight: 400,
      fontSize: 14,
    },
    selected: {
      fontWeight: 500,
    },
  },
  tableHeader: {
    fontSize: 11,
  },
  tableCell: {
    fontSize: 14,
  },
  subtitle1: {
    fontFamily: "'Inter', sans-serif",
    fontWeight: 400,
    fontSize: 24,
    lineHeight: '32px',
  },
  subtitle2: {
    fontFamily: "'Inter', sans-serif",
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '32px',
  },
  caption1: {
    fontFamily: "'Inter', sans-serif",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
  },
  links1: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 600,
  },
  // overline: {},
}
