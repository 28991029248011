import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Pencil: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Pencil"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9103 11.7624C16.751 11.7627 16.5957 11.7119 16.4673 11.6174L10.9673 7.57838C10.807 7.4601 10.7002 7.28297 10.6705 7.08596C10.6408 6.88895 10.6905 6.68821 10.8088 6.52788C10.9271 6.36756 11.1043 6.26079 11.3013 6.23107C11.4983 6.20134 11.699 6.2511 11.8593 6.36938L17.3593 10.4084C17.4866 10.5021 17.581 10.6335 17.6291 10.7841C17.6772 10.9346 17.6766 11.0964 17.6272 11.2466C17.5779 11.3967 17.4824 11.5274 17.3544 11.62C17.2264 11.7126 17.0724 11.7625 16.9143 11.7624H16.9103Z"
        fill="currentColor"
      ></path>
      <path
        d="M7.58144 20.748C6.87849 20.7452 6.20498 20.4654 5.70688 19.9694C5.20879 19.4733 4.92623 18.801 4.92044 18.098L4.90445 16.322C4.89652 15.309 5.20639 14.3189 5.79045 13.491L12.2554 4.52504C12.7402 3.86083 13.4685 3.41593 14.2808 3.28789C15.093 3.15986 15.9229 3.35914 16.5884 3.84204L18.3224 5.10604C18.9868 5.59035 19.4315 6.31871 19.5589 7.13092C19.6862 7.94314 19.4857 8.77267 19.0014 9.43704L12.6114 18.298C11.9768 19.196 11.0569 19.8526 10.0014 20.161L8.33444 20.646C8.08949 20.7153 7.83599 20.7496 7.58144 20.748ZM14.7614 4.74804C14.5082 4.74687 14.2583 4.80581 14.0323 4.92003C13.8063 5.03425 13.6107 5.20047 13.4614 5.40504L7.00944 14.36C6.60826 14.932 6.39666 15.6154 6.40445 16.314L6.42044 18.089C6.42202 18.27 6.46557 18.4481 6.54768 18.6094C6.62979 18.7706 6.74821 18.9106 6.89363 19.0183C7.03904 19.126 7.20746 19.1985 7.38565 19.23C7.56383 19.2615 7.7469 19.2513 7.92045 19.2L9.58644 18.716C10.3163 18.5012 10.952 18.0458 11.3904 17.424L17.7844 8.55704C17.9088 8.38722 17.9985 8.19453 18.0483 7.98999C18.0981 7.78546 18.107 7.57311 18.0746 7.36511C18.0422 7.15712 17.969 6.95756 17.8594 6.77789C17.7497 6.59821 17.6056 6.44195 17.4354 6.31804L15.7014 5.05404C15.4283 4.85538 15.0992 4.74826 14.7614 4.74804Z"
        fill="currentColor"
      ></path>
    </svg>
  </SvgIcon>
)
