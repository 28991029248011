import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const UserHearing: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M5.6416 19.999C5.6416 17.8904 7.30528 15.2656 12.0999 15.2656C16.8946 15.2656 18.5583 17.8715 18.5583 19.981"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2752 8.12542C16.2752 10.4034 14.4278 12.2508 12.1498 12.2508C9.87184 12.2508 8.02441 10.4034 8.02441 8.12542C8.02441 5.84743 9.87184 4 12.1498 4C14.4278 4 16.2752 5.84743 16.2752 8.12542Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M18.9521 5.61719C19.4284 6.33646 19.7058 7.19887 19.7058 8.12594C19.7058 9.053 19.4284 9.91542 18.9521 10.6347"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M5.34866 5.61719C4.87237 6.33646 4.59497 7.19887 4.59497 8.12594C4.59497 9.053 4.87237 9.91542 5.34866 10.6347"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
