import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

export const ErrorCircle: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Error circle"
      width="24"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 3.75C7.69329 3.75 4 7.44329 4 12C4 16.5558 7.69335 20.25 12.25 20.25C16.8067 20.25 20.5 16.5558 20.5 12C20.5 7.44329 16.8067 3.75 12.25 3.75ZM2.5 12C2.5 6.61487 6.86487 2.25 12.25 2.25C17.6351 2.25 22 6.61487 22 12C22 17.3841 17.6352 21.75 12.25 21.75C6.86481 21.75 2.5 17.3841 2.5 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2461 7.55664C12.6603 7.55664 12.9961 7.89243 12.9961 8.30664V12.6062C12.9961 13.0204 12.6603 13.3562 12.2461 13.3562C11.8319 13.3562 11.4961 13.0204 11.4961 12.6062V8.30664C11.4961 7.89243 11.8319 7.55664 12.2461 7.55664ZM12.2499 14.9441C12.6641 14.9441 12.9999 15.2799 12.9999 15.6941V15.7418C12.9999 16.156 12.6641 16.4918 12.2499 16.4918C11.8357 16.4918 11.4999 16.156 11.4999 15.7418V15.6941C11.4999 15.2799 11.8357 14.9441 12.2499 14.9441Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
