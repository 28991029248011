import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const MicroFilled: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Microphone"
      width="24"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5271 10.7851C20.5261 10.3711 20.1911 10.0371 19.7771 10.0371H19.7751C19.3611 10.0381 19.0261 10.3741 19.0271 10.7891C19.0351 14.3571 16.1391 17.2671 12.5751 17.2781C12.5731 17.2781 12.5721 17.2771 12.5701 17.2771H12.5671C10.8391 17.2711 9.21706 16.5951 7.99806 15.3701C6.77806 14.1451 6.10906 12.5171 6.11306 10.7891C6.11406 10.3751 5.77906 10.0381 5.36506 10.0371H5.36306C4.95006 10.0371 4.61406 10.3711 4.61306 10.7851C4.60806 12.9141 5.43206 14.9191 6.93506 16.4291C8.26006 17.7601 9.97206 18.5631 11.8201 18.7391V20.8751C11.8201 21.2891 12.1561 21.6251 12.5701 21.6251C12.9851 21.6251 13.3201 21.2891 13.3201 20.8751V18.7381C17.3661 18.3491 20.5371 14.9291 20.5271 10.7851Z"
        fill="currentColor"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5682 14.932H12.5732C14.8692 14.926 16.7342 13.053 16.7292 10.757V6.534C16.7292 4.241 14.8632 2.375 12.5692 2.375C10.2762 2.375 8.41016 4.241 8.41016 6.534V10.756C8.40816 11.869 8.83916 12.916 9.62416 13.705C10.4092 14.494 11.4552 14.93 12.5682 14.932Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
