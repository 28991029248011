import qs from 'query-string'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const store: Record<string, unknown> = {}

const STORE_KEY = 'debug-log'

/**
 * A logger that can be enabled via query params.
 *
 * Makes it possible to log values on production. The value is passed via a
 * function, so it has no impact when the logger is not active (which prevents
 * any errors being casted or expensive operations being run due to the logger).
 * The value is also memoized, which means it does not log same values multiple
 * times but only on change.
 *
 * How to use:
 * 1. Create the logger in your component:
 * ```
 * const debug = useDebugLog('some-namespace')
 * // ...
 * log('some-value-label', () => someValue)
 * ```
 *
 * 2. Activate the logger via URL query param (works on any route even after
 * query param is removed due to sync to localStorage):
 * https://recruiters.makipeople.com/assessments/list?debug=some-namespace
 *
 * 3. To deactivate, pass debug=false:
 * https://recruiters.makipeople.com/assessments/list?debug=false
 *
 * @param nameSpace
 * @returns
 */
export function useDebugLog(nameSpace: string) {
  const history = useHistory()
  const searchParams = qs.parse(history.location.search)

  useEffect(() => {
    if (searchParams.debug === 'false') {
      localStorage.removeItem(STORE_KEY)
      for (const key in store) {
        delete store[key]
      }
    } else if (searchParams.debug) {
      localStorage.setItem(STORE_KEY, `${searchParams.debug}`)
    }
  }, [searchParams.debug])

  return useCallback(
    function debug(label: string, resolveValue: () => unknown) {
      if (localStorage.getItem(STORE_KEY) === nameSpace && searchParams.debug !== 'false') {
        const value = resolveValue()
        if (store[label] !== value) {
          store[label] = value
          // eslint-disable-next-line no-console
          console.log(`${nameSpace}.${label}`, value)
        }
      }
    },
    [nameSpace, searchParams.debug],
  )
}
