import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const CheckedUser: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="User checked"
      width="24"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0197 13.2677C17.7537 13.2677 17.4987 13.1617 17.3117 12.9737L15.8507 11.5087C15.4607 11.1177 15.4617 10.4837 15.8527 10.0947C16.2437 9.70366 16.8757 9.70566 17.2667 10.0967L18.0207 10.8527L20.3297 8.54666C20.7197 8.15666 21.3527 8.15566 21.7437 8.54766C22.1337 8.93766 22.1337 9.57066 21.7437 9.96166L18.7267 12.9757C18.5397 13.1627 18.2847 13.2677 18.0197 13.2677Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.35964 12.5164C11.9676 12.5164 14.0896 10.3964 14.0896 7.79141C14.0896 5.18641 11.9676 3.06641 9.35964 3.06641C6.75164 3.06641 4.62964 5.18641 4.62964 7.79141C4.62964 10.3964 6.75164 12.5164 9.35964 12.5164Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3594 14.4961C6.3144 14.4961 2.2644 14.8291 2.2644 17.7051C2.2644 20.9331 7.6044 20.9331 9.3594 20.9331C12.4044 20.9331 16.4544 20.6001 16.4544 17.7241C16.4544 14.4961 11.1144 14.4961 9.3594 14.4961Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
