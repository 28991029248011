import { Box, Link, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

function Footer() {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <Box
      sx={{
        height: 64,
        display: 'flex',
        direction: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: '1px solid #ECECEE',
        backgroundColor: 'white',
      }}
    >
      {/*Link will be added when it's ready on the onboarding side*/}
      <Link
        href="https://help.makipeople.com/en/articles/70226-how-do-you-manage-your-assessment-during-a-maintenance-operation"
        underline="none"
        sx={{ marginRight: theme.spacing(2) }}
      >
        {t('maintenance.why', 'Why am I seeing this?')}
      </Link>
      <Link href="mailto:support@makipeople.com" underline="none">
        {t('maintenance.contact', 'Contact support')}
      </Link>
    </Box>
  )
}
export default Footer
