import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Webcam: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2495 3.75C8.90709 3.75 6.19824 6.45955 6.19824 9.8022C6.19824 13.1449 8.90709 15.8544 12.2495 15.8544C15.592 15.8544 18.3017 13.1447 18.3017 9.8022C18.3017 6.45969 15.592 3.75 12.2495 3.75ZM4.69824 9.8022C4.69824 5.6314 8.07839 2.25 12.2495 2.25C16.4204 2.25 19.8017 5.63126 19.8017 9.8022C19.8017 13.9731 16.4204 17.3544 12.2495 17.3544C8.07839 17.3544 4.69824 13.973 4.69824 9.8022Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2279 15.054C15.5876 14.8485 16.0457 14.9735 16.2511 15.3332L18.2394 18.8142C18.9808 20.1238 18.0346 21.7493 16.5262 21.7493H7.97743C6.45947 21.7493 5.51094 20.1224 6.26583 18.8026L6.26705 18.8004L8.2617 15.3395C8.46853 14.9806 8.92713 14.8573 9.286 15.0642C9.64488 15.271 9.76814 15.7296 9.56131 16.0885L7.56743 19.5481C7.38716 19.8646 7.6081 20.2493 7.97743 20.2493H16.5262C16.8855 20.2493 17.1088 19.8634 16.9346 19.5541C16.9344 19.5538 16.9342 19.5535 16.934 19.5531L14.9486 16.0772C14.7432 15.7175 14.8682 15.2594 15.2279 15.054Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2517 8.35547C11.4526 8.35547 10.8047 9.00338 10.8047 9.80249C10.8047 10.602 11.453 11.2505 12.2517 11.2505C13.0516 11.2505 13.6997 10.6018 13.6997 9.80249C13.6997 9.0036 13.052 8.35547 12.2517 8.35547ZM9.30469 9.80249C9.30469 8.17495 10.6242 6.85547 12.2517 6.85547C13.88 6.85547 15.1997 8.17474 15.1997 9.80249C15.1997 11.4298 13.8804 12.7505 12.2517 12.7505C10.6237 12.7505 9.30469 11.4296 9.30469 9.80249Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
