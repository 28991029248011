import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const UsersGroup: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.31006 19.5639C8.41506 19.5639 7.87423 18.92 7.83594 18.0232C7.83594 16.1978 9.61797 15.5681 12.303 15.5469C14.9928 15.5752 16.7796 16.2049 16.77 18.0232C16.7269 18.92 16.1893 19.5639 15.2959 19.5639H9.31006Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M12.3037 13.2324C13.5933 13.2324 14.6387 12.187 14.6387 10.8975C14.6387 9.6079 13.5933 8.5625 12.3037 8.5625C11.0141 8.5625 9.96875 9.6079 9.96875 10.8975C9.96875 12.187 11.0141 13.2324 12.3037 13.2324Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M6.85954 10.8828C4.63008 10.9004 3.15039 11.4233 3.15039 12.939C3.18218 13.6837 3.63125 14.2183 4.37441 14.2183H7.26158"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4412 10.8828C19.6707 10.9004 21.1504 11.4233 21.1504 12.939C21.1186 13.6837 20.6695 14.2183 19.9264 14.2183H17.0392"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.59506 8.31511C7.66583 8.31511 8.53386 7.44708 8.53386 6.37631C8.53386 5.30553 7.66583 4.4375 6.59506 4.4375C5.52428 4.4375 4.65625 5.30553 4.65625 6.37631C4.65625 7.44708 5.52428 8.31511 6.59506 8.31511Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M17.7057 8.31511C16.6349 8.31511 15.7669 7.44708 15.7669 6.37631C15.7669 5.30553 16.6349 4.4375 17.7057 4.4375C18.7765 4.4375 19.6445 5.30553 19.6445 6.37631C19.6445 7.44708 18.7765 8.31511 17.7057 8.31511Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  </SvgIcon>
)
