import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const EnterKey: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="32" height="21" viewBox="0 0 32 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1754 15.4432L9.61861 11.9091L13.1754 8.375V15.4432ZM12.0675 12.6364V11.1818H22.3288V12.6364H12.0675ZM20.9141 12.6364V5.36364H22.3629V12.6364H20.9141Z"
        fill="currentColor"
      />
      <rect x="0.5" y="0.5" width="31" height="20" rx="3.5" stroke="currentColor" />
    </svg>
  </SvgIcon>
)
