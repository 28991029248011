import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const RadioButtonUnChecked: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Circle"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.00098C16.9706 3.00098 21 7.03035 21 12.001C21 16.9706 16.9706 21 12 21C7.02938 21 3 16.9706 3 12.001C3 7.03035 7.02938 3.00098 12 3.00098Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
