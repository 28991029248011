import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Check: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M6.86645 8.65471C6.23349 7.96513 5.18127 7.93819 4.51626 8.59454C3.85125 9.25089 3.82527 10.342 4.45823 11.0316L9.44524 16.4647C10.0727 17.1482 11.1136 17.1815 11.7805 16.5394L19.4688 6.98692C20.1416 6.33913 20.1805 5.24845 19.5558 4.55083C18.9311 3.85321 17.8793 3.81281 17.2065 4.46061L11.0953 10.7463C10.876 10.9717 10.5363 11.0119 10.2738 10.8433L6.86645 8.65471Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
