import { useConvertMUISizeToPx } from '@maki/shared/utils'
import { Box, Breakpoint } from '@mui/material'

export type SpacerType = 'vertical' | 'horizontal'

export const spacerSize = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
}

type SpacerProps = {
  type?: SpacerType
  size?: Breakpoint | 'xxl'
}

export function Spacer({ type = 'vertical', size = 'md' }: SpacerProps) {
  const pixelsSize = useConvertMUISizeToPx(size)
  return type === 'vertical' ? (
    <Box role="none" width="100%" minHeight={pixelsSize} height={pixelsSize} />
  ) : (
    <Box role="none" width={pixelsSize} minWidth={pixelsSize} height="auto" />
  )
}
