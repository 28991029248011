import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Menu: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.958 16.7617C11.263 16.7617 10.697 17.3267 10.697 18.0227C10.697 18.7177 11.263 19.2827 11.958 19.2827C12.653 19.2827 13.219 18.7177 13.219 18.0227C13.219 17.3267 12.653 16.7617 11.958 16.7617Z"
        fill="currentColor"
      />
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.958 10.7402C11.263 10.7402 10.697 11.3052 10.697 12.0012C10.697 12.6962 11.263 13.2622 11.958 13.2622C12.653 13.2622 13.219 12.6962 13.219 12.0012C13.219 11.3052 12.653 10.7402 11.958 10.7402Z"
        fill="currentColor"
      />
      <path
        id="Vector_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.958 4.71875C11.263 4.71875 10.697 5.28375 10.697 5.97975C10.697 6.67475 11.263 7.23975 11.958 7.23975C12.653 7.23975 13.219 6.67475 13.219 5.97975C13.219 5.28375 12.653 4.71875 11.958 4.71875Z"
        fill="currentColor"
      />
      <path
        id="Vector_4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9961 16.7617C17.3011 16.7617 16.7351 17.3267 16.7351 18.0227C16.7351 18.7177 17.3011 19.2827 17.9961 19.2827C18.6901 19.2827 19.2561 18.7177 19.2561 18.0227C19.2561 17.3267 18.6901 16.7617 17.9961 16.7617Z"
        fill="currentColor"
      />
      <path
        id="Vector_5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9961 10.7402C17.3011 10.7402 16.7351 11.3052 16.7351 12.0012C16.7351 12.6962 17.3011 13.2622 17.9961 13.2622C18.6901 13.2622 19.2561 12.6962 19.2561 12.0012C19.2561 11.3052 18.6901 10.7402 17.9961 10.7402Z"
        fill="currentColor"
      />
      <path
        id="Vector_6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9961 7.23975C18.6901 7.23975 19.2561 6.67475 19.2561 5.97875C19.2561 5.28375 18.6901 4.71875 17.9961 4.71875C17.3011 4.71875 16.7351 5.28375 16.7351 5.97875C16.7351 6.67475 17.3011 7.23975 17.9961 7.23975Z"
        fill="currentColor"
      />
      <path
        id="Vector_7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0049 16.7617C5.3099 16.7617 4.7439 17.3267 4.7439 18.0227C4.7439 18.7177 5.3099 19.2827 6.0049 19.2827C6.6999 19.2827 7.2659 18.7177 7.2659 18.0227C7.2659 17.3267 6.6999 16.7617 6.0049 16.7617Z"
        fill="currentColor"
      />
      <path
        id="Vector_8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0049 10.7402C5.3099 10.7402 4.7439 11.3052 4.7439 12.0012C4.7439 12.6962 5.3099 13.2622 6.0049 13.2622C6.6999 13.2622 7.2659 12.6962 7.2659 12.0012C7.2659 11.3052 6.6999 10.7402 6.0049 10.7402Z"
        fill="currentColor"
      />
      <path
        id="Vector_9"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0049 4.71875C5.3099 4.71875 4.7439 5.28375 4.7439 5.97975C4.7439 6.67475 5.3099 7.23975 6.0049 7.23975C6.6999 7.23975 7.2659 6.67475 7.2659 5.97975C7.2659 5.28375 6.6999 4.71875 6.0049 4.71875Z"
        fill="currentColor"
      />

      <defs>
        <filter
          id="filter0_d_90_55"
          x="-4"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_90_55" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_90_55" result="shape" />
        </filter>
      </defs>
    </svg>
  </SvgIcon>
)
