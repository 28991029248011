import { i18n } from '@maki/shared/utils'
import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material'

import Countdown from './Countdown'

// /!\ NOTE /!\
// I have to use the translation with `i18n` from utils because we need to launch
// the init process. And the init is inside the utils file.
// For the other files we can use the useTranslation hooks from `react-i18next`

function CardMaintenance() {
  const theme = useTheme()

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        minHeight: '100%',
      }}
    >
      <Grid item xs={3}>
        <Card
          sx={{
            maxWidth: 700,
            borderRadius: theme.spacing(2),
            padding: `${theme.spacing(8)} ${theme.spacing(6)}`,
            transition: 'none',
            boxShadow: '0px 0px 16px 1px rgba(20, 22, 26, 0.12)',
            borderColor: '#E0E0E0',
            borderWidth: 1,
          }}
        >
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              id="maintenance-title"
              sx={{ marginBottom: theme.spacing(3) }}
            >
              {i18n.t('maintenance.title', 'Your assessment will be back in a bit.')}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              id="maintenance-subtitle1"
              sx={{ marginBottom: theme.spacing(3), color: '#7988A0' }}
            >
              {i18n.t(
                'maintenance.subtitle1',
                'We’re optimizing our systems to ensure you get the best assessment experience.',
              )}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              id="maintenance-subtitle2"
              sx={{ marginBottom: theme.spacing(4), color: '#7988A0' }}
            >
              {i18n.t(
                'maintenance.subtitle2',
                'Treat yourself to an extra break and check back in a few minutes. Thanks for your patience!',
              )}
            </Typography>
            <Countdown />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
export default CardMaintenance
