import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const NumberedList: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Numbered list"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 16C9 14.346 7.654 13 6 13C4.346 13 3 14.346 3 16C3 16.553 3.447 17 4 17C4.553 17 5 16.553 5 16C5 15.448 5.448 15 6 15C6.552 15 7 15.448 7 16C7 16.096 6.97 16.601 6.382 17.136C5.693 17.754 3.74 19.177 3.72 19.191C3.37 19.446 3.224 19.897 3.357 20.31C3.491 20.722 3.876 21 4.309 21H8C8.553 21 9 20.553 9 20C9 19.447 8.553 19 8 19H7.273C7.445 18.86 7.6 18.73 7.723 18.619C8.876 17.57 9 16.438 9 16Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.78032 6.26849L5.00032 6.09049V9.99949C5.00032 10.5525 5.44732 10.9995 6.00032 10.9995C6.55232 10.9995 7.00032 10.5525 7.00032 9.99949V3.99949C7.00032 3.61549 6.77932 3.26349 6.43132 3.09749C6.08332 2.93149 5.67232 2.98049 5.37232 3.22149L3.52432 4.71149C3.09432 5.05849 3.02732 5.68849 3.37432 6.11849C3.71932 6.54649 4.34932 6.61749 4.78032 6.26849Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.334 7H20C20.553 7 21 6.553 21 6C21 5.447 20.553 5 20 5H11.334C10.781 5 10.334 5.447 10.334 6C10.334 6.553 10.781 7 11.334 7Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 11H11.334C10.781 11 10.334 11.447 10.334 12C10.334 12.553 10.781 13 11.334 13H20C20.553 13 21 12.553 21 12C21 11.447 20.553 11 20 11Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 17H11.334C10.781 17 10.334 17.447 10.334 18C10.334 18.553 10.781 19 11.334 19H20C20.553 19 21 18.553 21 18C21 17.447 20.553 17 20 17Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
