import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const GridInterfaceAddPlus: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.448 14.75C15.6482 14.75 15 15.3982 15 16.198V18.802C15 19.6018 15.6482 20.25 16.448 20.25H19.052C19.8518 20.25 20.5 19.6018 20.5 18.802V16.198C20.5 15.3982 19.8518 14.75 19.052 14.75H16.448ZM13.5 16.198C13.5 14.5698 14.8198 13.25 16.448 13.25H19.052C20.6802 13.25 22 14.5698 22 16.198V18.802C22 20.4302 20.6802 21.75 19.052 21.75H16.448C14.8198 21.75 13.5 20.4302 13.5 18.802V16.198Z"
        fill="currentColor"
      />
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.75 2.48047C18.1642 2.48047 18.5 2.81626 18.5 3.23047V5.74609H21.0175C21.4317 5.74609 21.7675 6.08188 21.7675 6.49609C21.7675 6.91031 21.4317 7.24609 21.0175 7.24609H18.5V9.76047C18.5 10.1747 18.1642 10.5105 17.75 10.5105C17.3358 10.5105 17 10.1747 17 9.76047V7.24609H14.4805C14.0663 7.24609 13.7305 6.91031 13.7305 6.49609C13.7305 6.08188 14.0663 5.74609 14.4805 5.74609H17V3.23047C17 2.81626 17.3358 2.48047 17.75 2.48047Z"
        fill="currentColor"
      />
      <path
        id="Vector_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.448 3.75C4.64821 3.75 4 4.39821 4 5.198V7.802C4 8.60179 4.64821 9.25 5.448 9.25H8.052C8.85179 9.25 9.5 8.60179 9.5 7.802V5.198C9.5 4.39821 8.85179 3.75 8.052 3.75H5.448ZM2.5 5.198C2.5 3.56979 3.81979 2.25 5.448 2.25H8.052C9.68021 2.25 11 3.56979 11 5.198V7.802C11 9.43021 9.68021 10.75 8.052 10.75H5.448C3.81979 10.75 2.5 9.43021 2.5 7.802V5.198Z"
        fill="currentColor"
      />
      <path
        id="Vector_4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.448 14.75C4.64821 14.75 4 15.3982 4 16.198V18.802C4 19.6018 4.64821 20.25 5.448 20.25H8.052C8.85179 20.25 9.5 19.6018 9.5 18.802V16.198C9.5 15.3982 8.85179 14.75 8.052 14.75H5.448ZM2.5 16.198C2.5 14.5698 3.81979 13.25 5.448 13.25H8.052C9.68021 13.25 11 14.5698 11 16.198V18.802C11 20.4302 9.68021 21.75 8.052 21.75H5.448C3.81979 21.75 2.5 20.4302 2.5 18.802V16.198Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
