import { Box } from '@mui/material'
import { TimeCircle } from 'react-iconly'

function TimeCircleIconContainer() {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '-148px',
        left: '-260px',
      }}
    >
      <TimeCircle
        size={750}
        set="bulk"
        primaryColor="rgba(132, 132, 150, 0.1)"
        secondaryColor="rgba(132, 132, 150, 0.1)"
      />
    </Box>
  )
}

export default TimeCircleIconContainer
