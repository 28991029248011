/**
 * Truncate a string using slice and return this truncated string with ellipsis at the end.
 * @param str The string to truncate
 * @param length The length you want to keep
 * @returns The truncated string
 */
export const truncateString = (str: string, length: number) => {
  if (str.length <= length) {
    return str
  }
  return str.slice(0, length) + '...'
}
