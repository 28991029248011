import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const SpaceSatellite: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M7.01324 11.6347C4.85968 11.6347 3.11388 9.88885 3.11388 7.7353C3.11388 5.58174 4.85968 3.83594 7.01324 3.83594C9.16679 3.83594 10.9126 5.58174 10.9126 7.7353C10.9126 9.88885 9.16679 11.6347 7.01324 11.6347Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M8.44484 20.8609L3.37866 9.15161"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M8.85547 4.28442L21.1143 9.859"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M10.9123 7.24292L17.1677 12.3909"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_5"
        d="M7.47644 9.56763L16.4297 20.4859"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
