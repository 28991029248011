import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Diamond2: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M15.5812 15.4546L13.2015 20.5532C12.9234 21.149 12.0765 21.149 11.7984 20.5532L9.41975 15.4546C9.34235 15.29 9.21005 15.1576 9.04535 15.0812L3.94685 12.7015C3.35105 12.4234 3.35105 11.5765 3.94685 11.2984L9.04535 8.91885C9.21005 8.84235 9.34235 8.71005 9.41975 8.54535L11.7984 3.44685C12.0765 2.85105 12.9234 2.85105 13.2015 3.44685L15.5812 8.54535C15.6576 8.71005 15.79 8.84235 15.9556 8.91885L21.0532 11.2984C21.649 11.5765 21.649 12.4234 21.0532 12.7015L18.5044 13.8913"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
