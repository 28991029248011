import axios, { AxiosProgressEvent } from 'axios'
import { ErrorCode, FileRejection } from 'react-dropzone'
import { toast } from 'react-toastify'

import { i18n } from './i18nConfig'

export type OnUploadProgressType = (progressEvent: AxiosProgressEvent) => void
type uploadBinaryFileProps = {
  urlToUpload?: string
  file?: File
  maxFileSize?: number
  onUploadProgress?: OnUploadProgressType
}

export const uploadBinaryFile = async ({
  urlToUpload,
  file,
  maxFileSize,
  onUploadProgress,
}: uploadBinaryFileProps) => {
  if (!file || !urlToUpload || !maxFileSize) {
    return
  }

  return axios({
    url: urlToUpload,
    method: 'PUT',
    data: file,
    headers: {
      'Content-Type': file.type,
      'Cache-Control': 'no-store',
      'X-Goog-Content-Length-Range': `0,${maxFileSize}`,
    },
    onUploadProgress,
  })
}

export const handleDefaultDropRejection = (
  fileRejections: FileRejection[],
  maxFileSize: number,
) => {
  for (const fileRejection of fileRejections) {
    for (const fileError of fileRejection.errors) {
      // https://github.com/react-dropzone/react-dropzone/blob/a2039fd4bc5430a166858d71b7499a17036e68f9/src/utils/index.js#L9-L14
      switch (fileError.code) {
        case ErrorCode.FileTooLarge: {
          toast.error(
            i18n.t(
              'recruiters.components.questionBuilder.richTextEditor.error.fileTooLarge',
              'Your file is too big. Make sure it does not exceed {{maxSizeMB}} MB and try again.',
              {
                maxSizeMB: maxFileSize,
              },
            ),
          )
          break
        }
      }
    }
  }
}
