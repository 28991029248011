import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const SendLove1: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M12.884 16.8676H14.2608C15.243 16.8676 16.037 16.1609 16.037 15.1787C16.037 14.362 15.4839 13.7088 14.6919 13.5096C13.559 13.2251 12.4281 13.0021 11.2446 13.0308C9.37033 13.0764 8.05901 14.1211 6.57324 15.1153"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4164 6.51529C18.8555 5.14748 18.3451 3.57746 16.9118 3.11557C16.5411 2.99762 16.1476 2.96888 15.763 3.0343C15.3795 3.09971 15.0167 3.36436 14.7055 3.59827C14.3923 3.36634 14.0295 3.10269 13.6459 3.03826C13.2633 2.97284 12.8698 2.99961 12.4981 3.11557C11.0649 3.58142 10.5515 5.14748 10.9896 6.51529C11.6665 8.68497 14.7055 10.1043 14.7055 10.1043C14.7055 10.1043 17.6978 8.71074 18.4164 6.51529Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M12.8841 16.8988C13.5799 16.7967 14.2885 16.8998 14.9913 16.883C16.6218 16.8443 17.8994 15.4666 19.1403 14.5319C19.7747 14.0542 20.6618 14.1176 21.2228 14.6806C21.8452 15.305 21.8452 16.318 21.2228 16.9424C19.8827 18.2884 18.6983 19.5363 16.8824 20.243C14.3609 21.2233 12.0217 20.7475 9.46946 20.243C8.48126 20.0478 7.57236 20.0359 6.57227 20.0359"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M4.26172 21.0001H5.29353C6.0825 21.0001 6.57214 20.4441 6.57214 19.6571V15.0481C6.57214 14.2601 6.0825 13.7041 5.29452 13.7041H4.26172"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
