import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Retry: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.633 10.2705L16.669 12.5485C16.548 12.8335 16.271 13.0055 15.979 13.0055C15.881 13.0055 15.782 12.9865 15.687 12.9465L13.421 11.9885C13.039 11.8275 12.861 11.3875 13.023 11.0055C13.185 10.6245 13.625 10.4485 14.005 10.6065L14.811 10.9475C14.274 9.7025 13.031 8.8205 11.604 8.8205C9.677 8.8205 8.11 10.3875 8.11 12.3135C8.11 14.2405 9.677 15.8085 11.604 15.8085C12.58 15.8085 13.521 15.3945 14.183 14.6725C14.466 14.3675 14.94 14.3485 15.242 14.6275C15.548 14.9075 15.569 15.3825 15.288 15.6875C14.342 16.7175 12.999 17.3085 11.604 17.3085C8.85 17.3085 6.61 15.0675 6.61 12.3135C6.61 9.5605 8.85 7.3205 11.604 7.3205C13.581 7.3205 15.265 8.4495 16.075 10.1035L16.252 9.6855C16.414 9.3055 16.857 9.1275 17.235 9.2875C17.616 9.4485 17.795 9.8885 17.633 10.2705ZM16.217 2.8125H7.782C4.623 2.8125 2.5 5.0345 2.5 8.3395V16.2875C2.5 19.5925 4.623 21.8125 7.782 21.8125H16.216C19.376 21.8125 21.5 19.5925 21.5 16.2875V8.3395C21.5 5.0345 19.377 2.8125 16.217 2.8125Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
