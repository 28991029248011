import { Box, CircularProgress, CircularProgressProps } from '@mui/material'
import { ReactNode, Suspense } from 'react'
import { Switch } from 'react-router-dom'

const CenteredLoader = (props: CircularProgressProps) => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress {...props} />
  </Box>
)

export const SwitchWithSuspense = ({ children }: { children: ReactNode }) => (
  <Suspense fallback={<CenteredLoader />}>
    <Switch>{children}</Switch>
  </Suspense>
)
