import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Camera: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3031 4.88672C9.7631 4.88672 9.26704 5.17974 9.00577 5.65247C8.86941 5.89892 8.72267 6.15601 8.58439 6.3802C8.45464 6.59056 8.3045 6.81833 8.16229 6.97498L8.14976 6.98846C7.67544 7.48587 7.02718 7.7779 6.34163 7.80505C6.33174 7.80544 6.32184 7.80564 6.31195 7.80564C5.03578 7.80564 4.00168 8.83829 4 10.1142V15.756C4 17.61 5.50239 19.1129 7.35595 19.1129H17.1441C18.9968 19.1129 20.5 17.6099 20.5 15.756V10.1117C20.5 8.83493 19.4649 7.7998 18.1881 7.7998C18.1791 7.7998 18.1702 7.79964 18.1613 7.79932C17.4733 7.7748 16.8233 7.48074 16.3502 6.98453L16.3384 6.97191C16.196 6.81547 16.0501 6.5915 15.9255 6.38592C15.7927 6.16664 15.6531 5.91561 15.5233 5.67244C15.2637 5.18803 14.7619 4.88672 14.2154 4.88672H10.3031ZM7.69311 4.92658C8.21806 3.97702 9.21638 3.38672 10.3031 3.38672H14.2154C15.3189 3.38672 16.327 3.99591 16.8459 4.96484C16.969 5.1954 17.0949 5.42133 17.2084 5.60865C17.3252 5.80142 17.4051 5.91339 17.4429 5.95681C17.6438 6.16377 17.9165 6.28678 18.2038 6.29983C20.3018 6.30832 22 8.01176 22 10.1117V15.756C22 18.4381 19.8254 20.6129 17.1441 20.6129H7.35595C4.6735 20.6129 2.5 18.4379 2.5 15.756V10.1137C2.50242 8.01547 4.1987 6.31508 6.29447 6.30568C6.58352 6.29116 6.85549 6.1682 7.05679 5.96099C7.09863 5.91291 7.18396 5.79336 7.30772 5.59273C7.42825 5.39732 7.56191 5.1637 7.69311 4.92658Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4229 9.07812C17.8371 9.07812 18.1729 9.41391 18.1729 9.82812V9.8865C18.1729 10.3007 17.8371 10.6365 17.4229 10.6365C17.0086 10.6365 16.6729 10.3007 16.6729 9.8865V9.82812C16.6729 9.41391 17.0086 9.07812 17.4229 9.07812Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.40625 13.1048C8.40625 10.9832 10.1264 9.26172 12.2493 9.26172C14.3722 9.26172 16.0924 10.9832 16.0924 13.1048C16.0924 15.2276 14.3721 16.9479 12.2493 16.9479C10.1266 16.9479 8.40625 15.2276 8.40625 13.1048ZM12.2493 10.7617C10.9552 10.7617 9.90625 11.8113 9.90625 13.1048C9.90625 14.3991 10.955 15.4479 12.2493 15.4479C13.5437 15.4479 14.5924 14.3991 14.5924 13.1048C14.5924 11.8113 13.5435 10.7617 12.2493 10.7617Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
