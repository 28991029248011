import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Pause: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Pause"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.43263 3.5C5.96763 3.5 4.77563 4.692 4.77563 6.157V17.843C4.77563 19.308 5.96763 20.5 7.43263 20.5C8.89763 20.5 10.0896 19.308 10.0896 17.843V6.157C10.0896 4.692 8.89763 3.5 7.43263 3.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5673 3.5C15.1023 3.5 13.9103 4.692 13.9103 6.157V17.843C13.9103 19.308 15.1023 20.5 16.5673 20.5C18.0323 20.5 19.2243 19.308 19.2243 17.843V6.157C19.2243 4.692 18.0323 3.5 16.5673 3.5Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
