import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Leaves: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M12.2328 7.19633C11.6051 7.28788 10.9669 7.19545 10.3331 7.21042C8.86379 7.24563 7.71233 8.48777 6.59431 9.32936C6.0221 9.76072 5.22276 9.7035 4.71745 9.19555C4.15668 8.63302 4.15668 7.72012 4.71745 7.15672C5.92526 5.9445 6.99222 4.81856 8.62963 4.18209C10.9018 3.29824 13.0093 3.72784 15.3104 4.18209M12.2346 7.22432H10.9942"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M4.29688 20.5018H5.22738C5.93781 20.5018 6.37973 20.0009 6.37973 19.2914V15.1371C6.37973 14.4276 5.93781 13.9258 5.22738 13.9258H4.29688"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M13.6962 13.7501C12.675 13.4939 11.6556 13.2923 10.5895 13.3187C8.89929 13.3592 7.71788 14.3012 6.37891 15.1982"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M12.0688 16.8048C12.6965 16.7133 13.3347 16.8057 13.9686 16.7907C15.4379 16.7555 16.5893 15.5134 17.7073 14.6718C18.2796 14.2404 19.0789 14.2976 19.5842 14.8056C20.145 15.3681 20.145 16.281 19.5842 16.8444C18.3764 18.0566 17.3094 19.1826 15.672 19.8191C13.3999 20.7029 11.2924 20.2733 8.99121 19.8191M12.0671 16.7768H13.3075"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_5"
        d="M10.6055 10.2489C11.6266 10.5051 12.6461 10.7067 13.7121 10.6803C15.4024 10.6398 16.5838 9.69784 17.9228 8.80078"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_6"
        d="M20.0047 3.5H19.0742C18.3638 3.5 17.9219 4.00091 17.9219 4.71045V8.86472C17.9219 9.57426 18.3638 10.076 19.0742 10.076H20.0047"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
