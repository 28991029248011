import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const StrikeThrough: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="strikethrough"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.40759 10.2C7.95959 10.2 7.55259 9.897 7.43859 9.442C7.35959 9.126 7.32159 8.815 7.32359 8.494C7.32359 5.976 9.37859 4 12.0016 4C13.9816 4 15.6996 5.126 16.3766 6.868C16.5766 7.383 16.3216 7.962 15.8066 8.162C15.2906 8.364 14.7126 8.107 14.5126 7.593C14.1306 6.61 13.1686 6 12.0016 6C10.4746 6 9.32359 7.075 9.32359 8.5C9.32259 8.658 9.34059 8.805 9.37859 8.958C9.51259 9.493 9.18659 10.036 8.65159 10.17C8.56959 10.19 8.48859 10.2 8.40759 10.2Z"
        fill="currentColor"
      />
      <path
        d="M11.9999 19.9995C9.30188 19.9995 7.10588 17.9805 7.10588 15.4995C7.10588 14.9465 7.55388 14.4995 8.10588 14.4995C8.65888 14.4995 9.10588 14.9465 9.10588 15.4995C9.10588 16.8785 10.4039 17.9995 11.9999 17.9995C13.5959 17.9995 14.8939 16.8785 14.8939 15.4995C14.8939 14.3058 14.3175 13.6829 12.5403 13H5.88098C5.32898 13 4.88098 12.553 4.88098 12C4.88098 11.447 5.32898 11 5.88098 11H12.6609C12.702 10.9974 12.7435 10.9974 12.7851 11H18.119C18.671 11 19.119 11.447 19.119 12C19.119 12.553 18.671 13 18.119 13H16.0972C16.5703 13.6215 16.8939 14.4317 16.8939 15.4995C16.8939 17.9805 14.6989 19.9995 11.9999 19.9995Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
