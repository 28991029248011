import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const SpaceRocket: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M13.5463 8.42565C14.1763 7.79558 15.1989 7.79662 15.829 8.42668C16.459 9.05675 16.4601 10.0793 15.83 10.7094C15.1999 11.3395 14.1774 11.3384 13.5473 10.7084C12.9172 10.0783 12.9162 9.05572 13.5463 8.42565Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M19.9815 3.86764C14.5234 3.49692 7.78805 8.53506 7.06357 14.2048C7.04684 14.7001 7.22755 15.1684 7.56595 15.5068L8.93317 16.874C9.27156 17.2124 9.73992 17.3932 10.2352 17.3764C15.9048 16.6514 20.9429 9.91657 20.5722 4.45833C20.5503 4.13587 20.304 3.88955 19.9815 3.86764Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M16.5324 14.1758L16.2177 18.6062C16.2177 18.9908 16.0003 19.3424 15.6563 19.5145L13.2028 20.7412C12.9395 20.8729 12.632 20.8838 12.3601 20.7711C12.0881 20.6585 11.8784 20.4333 11.7853 20.154L10.6377 17.3502"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M10.3297 7.97656L5.89731 8.2608C5.51267 8.25815 5.15954 8.47305 4.98516 8.8159L3.74153 11.2608C3.60806 11.5232 3.59503 11.8306 3.70582 12.1034C3.8166 12.3761 4.04029 12.5874 4.31893 12.6824L7.11477 13.8493"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_5"
        d="M7.47509 18.8577C7.2047 20.6572 5.09813 20.3216 3.72461 20.528C3.93096 19.1544 3.60687 17.0594 5.4064 16.7891"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
