import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const FileUpload: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3813 9.67188H17.2725C19.1912 9.67285 20.746 11.2286 20.746 13.1473V17.766C20.746 19.6896 19.1863 21.2492 17.2618 21.2492H12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.62059 9.67188H6.73811C4.81455 9.67188 3.25391 11.2325 3.25391 13.1561V17.766C3.25488 19.6896 4.81455 21.2492 6.73811 21.2492H8.36902"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9996 3.25V14.6347M11.9996 3.25L9.24414 6.0181M11.9996 3.25L14.758 6.0181"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
