import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const AiMessage: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="sparkling"
      width="24"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8384 5.54639C15.5133 5.54639 15.2252 5.75583 15.125 6.06507C14.3108 8.57626 12.3424 10.5447 9.83119 11.3589C9.52194 11.4591 9.3125 11.7472 9.31251 12.0723C9.31252 12.3974 9.52197 12.6855 9.83122 12.7858C12.3423 13.5998 14.3107 15.5682 15.1249 18.0805C15.2252 18.3898 15.5133 18.5993 15.8384 18.5993C16.1635 18.5993 16.4516 18.3898 16.5519 18.0805C17.3661 15.5682 19.3344 13.5998 21.8455 12.7858C22.1548 12.6855 22.3643 12.3974 22.3643 12.0723C22.3643 11.7472 22.1548 11.4591 21.8456 11.3589C19.3344 10.5447 17.366 8.57627 16.5518 6.06508C16.4516 5.75583 16.1635 5.54639 15.8384 5.54639ZM15.8384 8.18595C16.7333 9.82722 18.0834 11.1774 19.7247 12.0723C18.0834 12.9672 16.7333 14.3174 15.8384 15.9592C14.9435 14.3174 13.5934 12.9672 11.9521 12.0723C13.5934 11.1774 14.9435 9.82723 15.8384 8.18595Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.46843 2.80371C7.14333 2.80371 6.85525 3.01315 6.75499 3.3224C6.51923 4.04955 5.94927 4.61951 5.22211 4.85527C4.91287 4.95554 4.70343 5.24363 4.70343 5.56873C4.70344 5.89382 4.91289 6.1819 5.22214 6.28216C5.94922 6.51787 6.51917 7.08777 6.75496 7.81531C6.85519 8.1246 7.1433 8.33409 7.46843 8.33409C7.79356 8.33409 8.08166 8.1246 8.18189 7.81531C8.41767 7.08776 8.98761 6.51787 9.71469 6.28216C10.0239 6.1819 10.2334 5.89382 10.2334 5.56873C10.2334 5.24363 10.024 4.95554 9.71472 4.85527C8.98757 4.61951 8.41762 4.04955 8.18186 3.3224C8.0816 3.01315 7.79352 2.80371 7.46843 2.80371ZM7.46842 5.07677C7.61735 5.25512 7.78201 5.41977 7.96035 5.56871C7.78201 5.71766 7.61735 5.88233 7.46842 6.0607C7.31948 5.88233 7.15483 5.71766 6.97648 5.56871C7.15483 5.41977 7.31948 5.25512 7.46842 5.07677Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.60353 13.717C6.27843 13.717 5.99035 13.9265 5.89009 14.2357C5.50449 15.425 4.57225 16.3573 3.38294 16.7429C3.07369 16.8432 2.86425 17.1312 2.86425 17.4563C2.86426 17.7814 3.07371 18.0695 3.38296 18.1698C4.57221 18.5553 5.50443 19.4875 5.89006 20.6774C5.99029 20.9867 6.2784 21.1962 6.60353 21.1962C6.92866 21.1962 7.21676 20.9867 7.317 20.6774C7.70262 19.4875 8.63482 18.5553 9.82406 18.1698C10.1333 18.0695 10.3428 17.7814 10.3428 17.4563C10.3428 17.1312 10.1333 16.8432 9.82409 16.7429C8.63478 16.3573 7.70256 15.425 7.31697 14.2357C7.2167 13.9265 6.92862 13.717 6.60353 13.717ZM6.60352 16.1305C6.96473 16.6462 7.41358 17.0951 7.92933 17.4563C7.41358 17.8175 6.96473 18.2664 6.60352 18.7823C6.24231 18.2664 5.79346 17.8175 5.2777 17.4563C5.79345 17.0951 6.24231 16.6462 6.60352 16.1305Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
