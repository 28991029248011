import { Box, useMediaQuery, useTheme } from '@mui/material'
import { useCallback, useRef } from 'react'
import QuickPinchZoom, { make3dTransformValue, UpdateAction } from 'react-quick-pinch-zoom'

type PinchableImageProps = {
  src: string
  alt?: string
}

export const PinchableImage = ({ src, alt }: PinchableImageProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const imgRef = useRef<HTMLImageElement>(null)
  const onUpdate = useCallback(({ x, y, scale }: UpdateAction) => {
    if (imgRef.current) {
      const value = make3dTransformValue({ x, y, scale })
      imgRef.current.style.setProperty('transform', value)
    }
  }, [])

  return (
    <Box sx={{ div: { overflow: 'visible' } }}>
      <QuickPinchZoom onUpdate={onUpdate} draggableUnZoomed={false}>
        <img
          alt="pinch"
          ref={imgRef}
          aria-label={alt}
          src={src}
          width="100%"
          style={{
            maxWidth: isMobile ? 'initial' : `calc(100vw -  ${theme.spacing(10)})`,
            maxHeight: isMobile ? 'initial' : `calc(100vh -  ${theme.spacing(10)})`,
          }}
        />
      </QuickPinchZoom>
    </Box>
  )
}
