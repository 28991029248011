import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const MicrophoneMute: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2856 11.5C15.3806 11.596 15.5086 11.646 15.6396 11.646C15.6976 11.646 15.7566 11.636 15.8136 11.615C15.9966 11.547 16.1236 11.378 16.1376 11.183L16.1378 11.1802C16.1477 11.0422 16.1576 10.905 16.1576 10.758V6.532C16.1576 4.24 14.2916 2.375 11.9996 2.375C10.6226 2.375 9.33658 3.056 8.55858 4.197C8.42358 4.396 8.44858 4.662 8.61858 4.832L15.2856 11.5Z"
        fill="currentColor"
      />
      <path
        d="M5.32263 4.16213C5.02963 3.86913 4.55463 3.86913 4.26163 4.16213C3.96863 4.45513 3.96863 4.93013 4.26163 5.22313L7.84163 8.80213V10.7551C7.83163 13.0491 9.69563 14.9231 11.9996 14.9331C12.5586 14.9331 13.1106 14.8121 13.6286 14.5891L15.3536 16.3141C14.3486 16.9301 13.1936 17.2761 12.0116 17.2781C12.0105 17.2781 12.0094 17.278 12.0084 17.2777C12.0075 17.2775 12.0067 17.2773 12.0059 17.277C12.0044 17.2766 12.003 17.2761 12.0016 17.2761C11.9996 17.2761 11.9976 17.2769 11.9956 17.2776C11.9936 17.2784 11.9916 17.2791 11.9896 17.2791C8.43363 17.2651 5.54163 14.3631 5.54163 10.8051V10.7861C5.54163 10.3711 5.20563 10.0451 4.79163 10.0451C4.37763 10.0451 4.04163 10.3911 4.04163 10.8051C4.04163 14.9401 7.21263 18.3521 11.2516 18.7401V20.8751C11.2516 21.2891 11.5876 21.6251 12.0016 21.6251C12.4156 21.6251 12.7516 21.2891 12.7516 20.8751V18.7401C14.0726 18.6131 15.3496 18.1571 16.4506 17.4111L18.6776 19.6391C18.8246 19.7851 19.0166 19.8581 19.2086 19.8581C19.4006 19.8581 19.5926 19.7851 19.7386 19.6391C20.0316 19.3461 20.0316 18.8711 19.7386 18.5781L5.32263 4.16213Z"
        fill="currentColor"
      />
      <path
        d="M18.4701 14.7237C18.3581 14.7237 18.2441 14.6987 18.1371 14.6457C17.7661 14.4617 17.6141 14.0127 17.7981 13.6407C18.2361 12.7557 18.4581 11.7947 18.4581 10.7847C18.4581 10.3707 18.7941 10.0347 19.2081 10.0347C19.6221 10.0347 19.9581 10.3707 19.9581 10.7847C19.9581 12.0277 19.6841 13.2127 19.1421 14.3067C19.0121 14.5707 18.7451 14.7237 18.4701 14.7237Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
