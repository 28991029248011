import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const RoadSign: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Road sign"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9961 21L11.9981 18"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0039 4.99965L12.0049 3"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13L12.002 10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.07878 5.33329C6.26852 5.12118 6.53964 4.99996 6.82423 5L17.5042 5.00137C18.0564 5.00144 18.504 5.44914 18.504 6.00137V9.00562C18.504 9.55796 18.0562 10.0057 17.5039 10.0056L6.82408 10.0043C6.53958 10.0042 6.26856 9.883 6.07889 9.67096L4.73515 8.16878C4.39558 7.78916 4.39558 7.21498 4.73515 6.83536L6.07878 5.33329Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9212 13.3294C17.7315 13.1173 17.4604 12.9961 17.1758 12.9961L6.49583 12.9975C5.94359 12.9975 5.49596 13.4452 5.49596 13.9975V17.0017C5.49596 17.5541 5.94375 18.0018 6.49609 18.0017L17.1759 18.0003C17.4604 18.0003 17.7314 17.8791 17.9211 17.6671L19.2649 16.1649C19.6044 15.7853 19.6044 15.2111 19.2649 14.8315L17.9212 13.3294Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
