import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const AddUser: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Add User"
      width="24"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0332 14.0305C5.92918 14.0305 2.46118 16.2795 2.46118 18.9415C2.46118 21.9775 6.78218 22.3285 10.0332 22.3285C11.9062 22.3285 17.6052 22.3285 17.6052 18.9215C17.6052 16.2695 14.1372 14.0305 10.0332 14.0305Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0009 11.7365H10.0329C12.8089 11.7365 15.0659 9.47851 15.0659 6.70351C15.0659 3.92851 12.8089 1.67151 10.0329 1.67151C7.25785 1.67151 4.99985 3.92851 4.99985 6.70151C4.98985 9.46751 7.23185 11.7265 10.0009 11.7365Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.289 9.40448H19.992V8.14948C19.992 7.73548 19.656 7.39948 19.242 7.39948C18.828 7.39948 18.492 7.73548 18.492 8.14948V9.40448H17.199C16.785 9.40448 16.449 9.74048 16.449 10.1545C16.449 10.5685 16.785 10.9045 17.199 10.9045H18.492V12.1595C18.492 12.5735 18.828 12.9095 19.242 12.9095C19.656 12.9095 19.992 12.5735 19.992 12.1595V10.9045H21.289C21.703 10.9045 22.039 10.5685 22.039 10.1545C22.039 9.74048 21.703 9.40448 21.289 9.40448Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
