import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const AiBrainChange: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M15.1872 19.3145C14.1962 19.8355 13.8896 21.5009 13.8896 21.5009"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        id="Vector_2"
        d="M13.1433 17.1948C13.3812 18.0567 13.3413 18.8803 14.2518 19.081L17.0362 19.7088C17.6879 19.846 18.271 19.5202 18.3739 18.8342L18.6841 16.191C18.7382 15.9094 18.9337 15.6755 19.2013 15.5723L20.1288 15.2146C20.6189 15.0256 20.777 14.4611 20.4633 14.0397C19.7508 13.0826 18.7453 11.5182 19.0492 10.1409C19.6195 7.55713 18.5181 5.61406 16.6351 4.02593C15.4643 3.03852 14.0933 2.63056 12.6333 2.52734"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        id="Vector_3"
        d="M5.25671 7.60156H3.375V9.35192"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M9.15124 3V4.88171H7.26953"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_5"
        d="M3.43945 4.73066C3.9668 3.80926 4.95436 3.19727 6.0937 3.19727C8.13595 3.19727 9.15172 4.8912 9.15172 4.8912"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_6"
        d="M8.73895 7.77867C8.2116 8.69131 7.22307 9.30233 6.09443 9.30233C4.41023 9.30233 3.375 7.60938 3.375 7.60938"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_7"
        d="M4.30859 12.8633C5.7638 15.468 6.43596 18.4308 5.19335 21.2646"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  </SvgIcon>
)
