import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Target: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.49902 12.2827C2.49902 7.09247 6.70532 2.88672 11.8951 2.88672C15.014 2.88672 17.7869 4.41109 19.4929 6.74629C19.7373 7.08076 19.6642 7.54998 19.3297 7.79432C18.9953 8.03867 18.526 7.96562 18.2817 7.63115C16.8463 5.66635 14.5156 4.38672 11.8951 4.38672C7.53369 4.38672 3.99902 7.92096 3.99902 12.2827C3.99902 16.636 7.53419 20.1787 11.8951 20.1787C16.2571 20.1787 19.7922 16.6359 19.7922 12.2827C19.7922 11.815 19.7492 11.3567 19.6644 10.9081C19.5874 10.5011 19.855 10.1088 20.262 10.0318C20.669 9.95484 21.0613 10.2224 21.1382 10.6294C21.2406 11.1708 21.2922 11.7225 21.2922 12.2827C21.2922 17.4635 17.0863 21.6787 11.8951 21.6787C6.70481 21.6787 2.49902 17.4635 2.49902 12.2827Z"
        fill="currentColor"
        color="currentColor"
      />
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8949 8.35156C9.72845 8.35156 7.96191 10.1098 7.96191 12.2845C7.96191 14.4492 9.72939 16.2166 11.8949 16.2166C13.6451 16.2166 15.1279 15.0717 15.6299 13.4959C15.7556 13.1012 16.1775 12.8832 16.5722 13.009C16.9668 13.1347 17.1848 13.5566 17.0591 13.9512C16.3639 16.1335 14.3145 17.7166 11.8949 17.7166C8.90126 17.7166 6.46191 15.2779 6.46191 12.2845C6.46191 9.27924 8.9022 6.85156 11.8949 6.85156C13.7031 6.85156 15.3135 7.72947 16.2842 9.10257C16.5233 9.44079 16.443 9.90882 16.1048 10.1479C15.7665 10.3871 15.2985 10.3067 15.0594 9.96848C14.3659 8.98757 13.2095 8.35156 11.8949 8.35156Z"
        fill="currentColor"
        color="currentColor"
      />
      <path
        id="Vector_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8563 5.02315C22.1002 5.3579 22.0267 5.82704 21.6919 6.071L12.3412 12.886C12.0065 13.13 11.5373 13.0564 11.2934 12.7216C11.0494 12.3869 11.123 11.9177 11.4577 11.6738L20.8084 4.85879C21.1432 4.61482 21.6123 4.68841 21.8563 5.02315Z"
        fill="currentColor"
        color="currentColor"
      />
    </svg>
  </SvgIcon>
)
