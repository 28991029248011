import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const LinkBreak: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.38702 8.98163H3.5C3.30109 8.98163 3.11032 8.9026 2.96967 8.76195C2.82901 8.6213 2.75 8.43054 2.75 8.23163C2.75 8.03272 2.82901 7.84195 2.96967 7.70129C3.11032 7.56064 3.30109 7.48163 3.5 7.48163H5.38702C5.58594 7.48163 5.77671 7.56064 5.91736 7.70129C6.05801 7.84195 6.13702 8.03272 6.13702 8.23163C6.13702 8.43054 6.05801 8.6213 5.91736 8.76195C5.77671 8.9026 5.58594 8.98163 5.38702 8.98163ZM7.966 6.40262C7.76709 6.40262 7.57632 6.32361 7.43567 6.18295C7.29502 6.0423 7.216 5.85153 7.216 5.65262V3.76562C7.216 3.56671 7.29502 3.37594 7.43567 3.23529C7.57632 3.09464 7.76709 3.01562 7.966 3.01562C8.16492 3.01562 8.35569 3.09464 8.49634 3.23529C8.63699 3.37594 8.716 3.56671 8.716 3.76562V5.65262C8.716 5.85153 8.63699 6.0423 8.49634 6.18295C8.35569 6.32361 8.16492 6.40262 7.966 6.40262Z"
        fill="currentColor"
      />
      <path
        d="M17.0352 22.5149C16.8362 22.5149 16.6455 22.4358 16.5049 22.2952C16.3642 22.1545 16.2852 21.9638 16.2852 21.7649V19.8779C16.2852 19.679 16.3642 19.4882 16.5049 19.3475C16.6455 19.2069 16.8362 19.1279 17.0352 19.1279C17.2341 19.1279 17.4248 19.2069 17.5655 19.3475C17.7061 19.4882 17.7852 19.679 17.7852 19.8779V21.7659C17.7849 21.9646 17.7058 22.1551 17.5652 22.2955C17.4246 22.436 17.2339 22.5149 17.0352 22.5149ZM21.5002 18.0469H19.6122C19.4133 18.0469 19.2225 17.9678 19.0818 17.8272C18.9412 17.6865 18.8622 17.4958 18.8622 17.2969C18.8622 17.098 18.9412 16.9072 19.0818 16.7665C19.2225 16.6259 19.4133 16.5469 19.6122 16.5469H21.5002C21.6991 16.5469 21.8899 16.6259 22.0305 16.7665C22.1712 16.9072 22.2502 17.098 22.2502 17.2969C22.2502 17.4958 22.1712 17.6865 22.0305 17.8272C21.8899 17.9678 21.6991 18.0469 21.5002 18.0469Z"
        fill="currentColor"
      />
      <path
        d="M9.81869 21.2969C8.66197 21.2961 7.53147 20.9525 6.56991 20.3096C5.60834 19.6666 4.8589 18.7531 4.41622 17.6845C3.97354 16.6158 3.85748 15.4399 4.08272 14.3053C4.30797 13.1708 4.86439 12.1284 5.68172 11.3099L6.66469 10.3269C6.73385 10.2552 6.81662 10.198 6.9081 10.1587C6.99959 10.1193 7.09795 10.0986 7.19753 10.0977C7.29711 10.0968 7.39593 10.1157 7.48812 10.1534C7.58031 10.191 7.66407 10.2467 7.73452 10.3171C7.80497 10.3875 7.8607 10.4712 7.89846 10.5633C7.93621 10.6555 7.95525 10.7542 7.95443 10.8538C7.95361 10.9534 7.93296 11.0518 7.8937 11.1434C7.85443 11.2349 7.79733 11.3177 7.72573 11.3869L6.74172 12.3709C5.93514 13.189 5.48471 14.2928 5.48873 15.4416C5.49274 16.5904 5.95086 17.691 6.76314 18.5034C7.57542 19.3159 8.67597 19.7741 9.82479 19.7783C10.9736 19.7825 12.0775 19.3323 12.8957 18.5259L13.8817 17.5419C13.9505 17.4683 14.0334 17.4094 14.1255 17.3686C14.2176 17.3278 14.3169 17.3059 14.4176 17.3043C14.5183 17.3027 14.6183 17.3214 14.7116 17.3593C14.8049 17.3972 14.8897 17.4536 14.9608 17.5249C15.0319 17.5963 15.0878 17.6812 15.1254 17.7746C15.1629 17.8681 15.1812 17.9682 15.1793 18.0689C15.1773 18.1696 15.1551 18.2688 15.1139 18.3607C15.0728 18.4527 15.0135 18.5354 14.9397 18.6039L13.9547 19.5869C13.4119 20.1303 12.7671 20.561 12.0573 20.8545C11.3476 21.1479 10.5867 21.2983 9.81869 21.2969Z"
        fill="currentColor"
      />
      <path
        d="M17.6838 15.5456C17.5355 15.5457 17.3904 15.5018 17.2671 15.4194C17.1437 15.3371 17.0475 15.22 16.9907 15.083C16.9339 14.946 16.9189 14.7952 16.9478 14.6497C16.9766 14.5042 17.048 14.3706 17.1528 14.2656L18.1448 13.2746C18.7541 12.6668 19.1692 11.8919 19.3376 11.0479C19.506 10.2039 19.42 9.32891 19.0905 8.53386C18.7611 7.73881 18.203 7.05947 17.487 6.58196C16.771 6.10444 15.9294 5.85026 15.0688 5.85161C14.4976 5.84936 13.9316 5.96024 13.4035 6.17787C12.8754 6.39551 12.3956 6.71558 11.9918 7.11962L11.0018 8.10961C10.8604 8.24629 10.671 8.32199 10.4743 8.32038C10.2777 8.31876 10.0895 8.23996 9.95041 8.10097C9.81129 7.96198 9.7323 7.77391 9.7305 7.57726C9.7287 7.38061 9.80421 7.19112 9.94077 7.04961L10.9318 6.05962C11.4741 5.51541 12.119 5.08422 12.8291 4.79103C13.5393 4.49785 14.3005 4.34848 15.0688 4.35161C16.2247 4.3531 17.3543 4.69696 18.315 5.33978C19.2757 5.98261 20.0245 6.89561 20.4669 7.96356C20.9092 9.03152 21.0253 10.2066 20.8005 11.3404C20.5757 12.4743 20.0201 13.5162 19.2038 14.3346L18.2128 15.3256C18.1434 15.3953 18.061 15.4506 17.9702 15.4883C17.8795 15.5261 17.7821 15.5456 17.6838 15.5456Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
