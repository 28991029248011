export const colors = {
  grey: {
    0: '#FFFFFF',
    50: '#f8f9fc',
    100: '#F0F2F6',
    200: '#DFDFE4',
    300: '#C1C1CA',
    400: '#A2A2B0',
    500: '#848496',
    600: '#68687A',
    700: '#4E4E5C',
    800: '#34343D',
    900: '#1B1B1F',
    1000: '#000', // Black
  },
  // UI brand colors
  primaryStyle: {
    50: '#F8FAFF',
    100: '#F0F5FF',
    200: '#DCE9FF',
    300: '#E9F0FC',
    400: '#B9D3FF',
    500: '#5A19E6',
    600: '#4614B3',
    700: '#320E81',
    800: '#1F094E',
    900: '#0B031C',
  },
  errorStyle: {
    50: '#FBEDEC',
    100: '#FBEDEC',
    200: '#fdd8d6',
    300: '#faa6a0',
    400: '#f7756b',
    500: '#f44336',
    600: '#e51b0d',
    700: '#b0150a',
    800: '#7b0f07',
    900: '#460804',
  },
  warningStyle: {
    50: '#FFF5E5',
    100: '#FFF5E5',
    200: '#ffebce',
    300: '#ffd596',
    400: '#ffbe5e',
    500: '#ffa726',
    600: '#ed8d00',
    700: '#b56b00',
    800: '#7d4a00',
    900: '#452900',
  },
  text: {
    primary: '#1B1B1F', // grey 900
    secondary: '#68687A', // grey 700
    tertiary: '#8B909B', // grey 500
    light: '#C1C1CA', // Light - Gray - 300
    white: '#FFFFFF', // White - Gray - 000
    accentPrimary: '#3D85FF', // Accent - Primary - 500
    error: '#f44336', // Accent - Error
    success: '#00B64C', // Accent - Success
  },
  primary: {
    dark: '#003DA3',
    main: '#3D85FF',
    light: '#A3C5FF',
  },
  secondary: {
    dark: '#D57556',
    main: '#EE805C',
    light: '#DFDFE4',
  },
  info: {
    dark: '#0447FF',
    main: '#3C70FF',
    light: '#7499FF',
  },
  error: {
    light: '#FAA6A0',
    main: '#F44336',
    dark: '#7B0F07',
  },
  success: {
    light: '#ABECC7',
    main: '#00B64C',
    dark: '#00411B',
  },
  warning: {
    light: '#FFEBCE',
    main: '#FFA726',
    dark: '#7D4A00',
  },
  functional: {
    progress: {
      inProgress: '#FFDF6C',
      notStart: '#E5EFFF',
      done: '#4FCDB0',
    },
    activityType: {
      official: '#1E88E5',
      customActivity: '#00897B',
      customQuestion: '#3949AB',
    },
    avatars: {
      violet: {
        background: '#E5ECFF',
        text: '#1E3B8C',
      },
      blue: {
        background: '#E5F3FF',
        text: '#18558C',
      },
      green: {
        background: '#E5FFF0',
        text: '#00682B',
      },
      yellow: {
        background: '#FFF5E5',
        text: '#8C5B14',
      },
      salmon: {
        background: '#FEE8E7',
        text: '#86231B',
      },
    },
    chips: {
      blueCollar: {
        background: '#E4EBFF',
        font: '#0447FF',
      },
      enneagram: {
        first: {
          background: '#8B909B',
          font: '#FFFFFF',
        },
        second: {
          background: '#A2A6AF',
          font: '#FFFFFF',
        },
        third: {
          font: '#FFFFFF',
          background: '#B9BCC3',
        },
      },
      leadership: {
        background: '#E4EBFF',
        font: '#0447FF',
      },
      darkSide: {
        background: '#E4EBFF',
        low: {
          background: '#FFF5E5',
          font: '#ED8D00',
        },
        middle: {
          background: '#E5FFF0',
          font: '#00B64C',
        },
        high: {
          background: '#FBEDEC',
          font: '#E51B0D',
        },
        veryhigh: {
          background: '#F44336',
          font: 'white',
        },
      },

      valueFit: {
        background: '#E4EBFF',
        low: {
          background: '#FBEDEC',
          font: '#E51B0D',
        },
        medium: {
          background: '#FFF5E5',
          font: '#ED8D00',
        },
        high: {
          background: '#E5FFF0',
          font: '#00B64C',
        },
        veryhigh: {
          background: '#00B64C',
          font: 'white',
        },
      },
    },
  },
}
