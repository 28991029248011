import { Box, IconButton, Modal, Typography, useMediaQuery, useTheme } from '@mui/material'
import { X as CloseIcon } from 'phosphor-react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PinchableImage } from './PinchableImage'

type PropTypes = {
  src: string
  maxHeight?: string
  maxWidth?: string
  borderRadius?: string
  alt?: string
}
export const ZoomableImage = ({ src, maxHeight, maxWidth, borderRadius, alt }: PropTypes) => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const matchDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const { t } = useTranslation()

  const toggleModal = useCallback(() => {
    setOpen(!open)
  }, [open])

  const handleModalClick = () => {
    if (matchDesktop) {
      toggleModal()
    }
  }

  return (
    <>
      <Box onClick={toggleModal} sx={{ position: 'relative' }}>
        <img
          alt="zoom"
          src={src}
          aria-label={alt}
          style={{
            maxWidth: '100%',
            cursor: 'zoom-in',
            ...(maxHeight ? { maxHeight } : {}),
            ...(maxWidth ? { maxWidth } : {}),
            borderRadius,
          }}
        />
      </Box>
      <Modal open={open} onClose={toggleModal} onClick={handleModalClick}>
        <Box
          sx={{
            cursor: 'zoom-out',
            height: '100vh',
            width: { xs: 'initial', md: '100%' },
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: { xs: 'flex', md: 'none' },
              position: 'absolute',
              top: 0,
              alignItems: 'center',
              justifyContent: { xs: 'space-between', md: 'flex-end' },
              width: '100%',
              padding: theme.spacing(1),
            }}
          >
            <Typography color="text.white" sx={{ display: { xs: 'block', md: 'none' } }}>
              {t(
                'candidates.public.assessments.running.zoomableImage.pinchTitleMobile',
                'Pinch to zoom in and out',
              )}
            </Typography>
            <IconButton
              sx={{ zIndex: 1 }}
              aria-label="close"
              onClick={toggleModal}
              color="secondary"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PinchableImage src={src} alt={alt} />
          </Box>
        </Box>
      </Modal>
    </>
  )
}
