import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const SpeakerMegaphone: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M17.5918 16.1346C17.5918 16.1346 7.32474 14.0223 6.05594 13.7051C4.78618 13.3879 3.19922 11.724 3.19922 9.57955C3.19922 7.43506 4.78618 5.77025 6.05594 5.45306C7.32474 5.13586 17.5422 3.03418 17.5422 3.03418"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M6.32422 13.7637V18.6238C6.32422 19.9364 7.38868 20.9999 8.70126 20.9999C10.0129 20.9999 11.0773 19.9364 11.0773 18.6238V14.8379"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8006 9.57748C20.8006 13.2097 19.4773 16.155 17.8456 16.155C16.2139 16.155 14.8906 13.2097 14.8906 9.57748C14.8906 5.94527 16.2139 3 17.8456 3C19.4773 3 20.8006 5.94527 20.8006 9.57748Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
