import { colors } from '../system'

interface PrimaryStylePaletteOptions {
  50: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}

interface BorderPaletteOptions {
  main: string
  accent: string
}

interface FunctionalPaletteOptions {
  progress: {
    inProgress: string
    notStart: string
    done: string
  }
  activityType: {
    official: string
    customActivity: string
    customQuestion: string
  }
  avatars: {
    violet: {
      background: string
      text: string
    }
    blue: {
      background: string
      text: string
    }
    green: {
      background: string
      text: string
    }
    yellow: {
      background: string
      text: string
    }
    salmon: {
      background: string
      text: string
    }
  }
  chips: {
    blueCollar: {
      background: string
      font: string
    }
    enneagram: {
      first: {
        background: string
        font: string
      }
      second: {
        background: string
        font: string
      }
      third: {
        background: string
        font: string
      }
    }
    leadership: {
      background: string
      font: string
    }
    darkSide: {
      background: string
      low: {
        background: string
        font: string
      }
      middle: {
        background: string
        font: string
      }
      high: {
        background: string
        font: string
      }
      veryhigh: {
        background: string
        font: string
      }
    }
    valueFit: {
      background: string
      low: {
        background: string
        font: string
      }
      medium: {
        background: string
        font: string
      }
      high: {
        background: string
        font: string
      }
      veryhigh: {
        background: string
        font: string
      }
    }
  }
}

declare module '@mui/material' {
  interface Color {
    0: string
    1000: string
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    primaryStyle: PrimaryStylePaletteOptions
    errorStyle: PrimaryStylePaletteOptions
    warningStyle: PrimaryStylePaletteOptions
    border: BorderPaletteOptions
    functional: FunctionalPaletteOptions
  }
  interface PaletteOptions {
    primaryStyle?: PrimaryStylePaletteOptions
    errorStyle: PrimaryStylePaletteOptions
    warningStyle?: PrimaryStylePaletteOptions
    border?: BorderPaletteOptions
    functional?: FunctionalPaletteOptions
  }

  // Extends text values
  interface TypeText {
    white?: string
    tertiary?: string
    accentPrimary?: string
    error?: string
    success?: string
  }

  interface TypeBackground {
    neutral: string
    assessmentBuilder: string
  }
}

// SETUP COLORS
const PRIMARY = {
  ...colors.primary,
}
const SECONDARY = {
  ...colors.secondary,
}
const INFO = {
  ...colors.info,
}
const SUCCESS = {
  ...colors.success,
}
const WARNING = {
  ...colors.warning,
}
const ERROR = {
  ...colors.error,
}
const GREY = {
  ...colors.grey,
}
const TEXT = {
  ...colors.text,
}
const BACKGROUND = {
  paper: colors.grey[0],
  default: colors.grey[0],
  neutral: colors.primaryStyle[200],
}
const PRIMARY_STYLE = {
  ...colors.primaryStyle,
}
const ERROR_STYLE = {
  ...colors.errorStyle,
}
const WARNING_STYLE = {
  ...colors.warningStyle,
}
const BORDER = {
  main: colors.primaryStyle[200],
  accent: colors.primaryStyle[500],
}
const FUNCTIONAL = {
  ...colors.functional,
}

const COLORS_SETUP = {
  grey: GREY,
  primaryStyle: { ...PRIMARY_STYLE },
  errorStyle: { ...ERROR_STYLE },
  warningStyle: { ...WARNING_STYLE },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS, contrastText: colors.grey[800] },
  warning: { ...WARNING, contrastText: colors.grey[800] },
  error: { ...ERROR },
  divider: colors.primaryStyle[500],
  text: { ...TEXT },
  background: { ...BACKGROUND },
  border: { ...BORDER },
  action: {
    hover: PRIMARY_STYLE[100],
  },
  functional: { ...FUNCTIONAL },
}

export const palette = {
  ...COLORS_SETUP,
}
