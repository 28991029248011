import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Filter2: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Iconly/Two-tone/Filter-2"
        stroke="currentColor"
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Filter"
          transform="translate(3.000000, 3.000000)"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <path
            d="M1.56517107,0 C0.701077306,0 0,0.712864241 0,1.59040229 L0,2.52644287 C0,3.17647105 0.247188351,3.80158149 0.689357168,4.27176854 L5.53510122,9.42432527 L5.53723215,9.42107513 C6.47271221,10.3787833 6.99905292,11.6734228 6.99905292,13.0233146 L6.99905292,17.5951795 C6.99905292,17.9006928 7.31869303,18.0957012 7.58399432,17.9516117 L10.3435539,16.4478798 C10.7601515,16.2203699 11.0201255,15.7783508 11.0201255,15.2984133 L11.0201255,13.0113975 C11.0201255,11.6690893 11.5390079,10.3798667 12.4659642,9.42432527 L17.3117083,4.27176854 C17.7528116,3.80158149 18,3.17647105 18,2.52644287 L18,1.59040229 C18,0.712864241 17.2999882,0 16.4358944,0 L1.56517107,0 Z"
            id="Stroke-1"
          />
        </g>
      </g>
    </svg>
  </SvgIcon>
)
