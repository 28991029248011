import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Question: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="question mark"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5918 18.9998V18.9287"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5921 14.9275C11.5731 13.44 12.9254 12.8084 13.9324 12.2359C15.1585 11.5582 15.9893 10.4822 15.9893 8.9878C15.9893 6.77469 14.1996 5 12.0005 5C9.78841 5 8.01172 6.77469 8.01172 8.9878"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
