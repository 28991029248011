import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

import { ThemeConfig } from '../design/material'
import MaintenanceContainer from './MaintenanceContainer'

function App() {
  return (
    <ThemeConfig>
      <MaintenanceContainer />
    </ThemeConfig>
  )
}

export default App
