export function getLabelScore(
  score: number,
  levelMinThresholds?: number[] | null,
  levelLabels?: string[] | null,
): string | null {
  if (levelMinThresholds && levelLabels && levelMinThresholds.length === levelLabels.length) {
    for (let index = levelMinThresholds.length - 1; index >= 0; index--) {
      if (score >= levelMinThresholds[index]) {
        return levelLabels[index]
      }
    }
  }

  return null
}
