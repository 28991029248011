import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const CameraClose: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.314 14.5685C14.607 14.8615 14.607 15.3365 14.314 15.6295C14.168 15.7755 13.976 15.8485 13.784 15.8485C13.592 15.8485 13.4 15.7755 13.253 15.6295L12 14.3755L10.744 15.6315C10.597 15.7785 10.405 15.8515 10.213 15.8515C10.022 15.8515 9.83 15.7785 9.683 15.6315C9.39 15.3395 9.39 14.8635 9.683 14.5715L10.939 13.3155L9.683 12.0595C9.39 11.7665 9.39 11.2925 9.683 10.9995C9.976 10.7065 10.451 10.7065 10.744 10.9995L12 12.2555L13.253 11.0025C13.546 10.7095 14.021 10.7095 14.314 11.0025C14.607 11.2955 14.607 11.7705 14.314 12.0635L13.061 13.3155L14.314 14.5685ZM17.957 6.8515C17.594 6.8375 17.255 6.6855 17.013 6.4315C16.968 6.3825 16.791 6.1615 16.376 5.3835C15.898 4.4915 14.974 3.9375 13.964 3.9375H10.053C9.058 3.9375 8.142 4.4785 7.661 5.3485C7.224 6.1395 7.043 6.3725 6.995 6.4265C6.744 6.6895 6.405 6.8415 6.062 6.8565C4.1 6.8565 2.502 8.4525 2.5 10.4145V16.0565C2.5 18.5965 4.566 20.6635 7.106 20.6635H16.894C19.433 20.6635 21.5 18.5965 21.5 16.0565V10.4125C21.5 8.4485 19.902 6.8515 17.957 6.8515Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
