import { format as formatDate } from 'date-fns'
import {
  bg as bgLocale,
  cs as csLocale,
  da as daLocale,
  de as deLocale,
  el as elLocale,
  enUS as enLocale,
  es as esLocale,
  et as etLocale,
  fi as fiLocale,
  fr as frLocale,
  frCA as frCALocale,
  he as heLocale,
  hi as hiLocale,
  hr as hrLocale,
  hu as huLocale,
  it as itLocale,
  ja as jaLocale,
  ko as koLocale,
  lt as ltLocale,
  lv as lvLocale,
  ms as msLocale,
  nb as noLocale,
  nl as nlLocale,
  pl as plLocale,
  pt as ptLocale,
  ptBR as ptBRLocale,
  ro as roLocale,
  ru as ruLocale,
  sq as sqLocale,
  sv as svLocale,
  th as thLocale,
  tr as trLocale,
  vi as viLocale,
  zhCN as zhCNLocale,
  zhHK as zhHKLocale,
} from 'date-fns/locale'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translationBG from '../../../../../i18n/bg_BG/bg_BG.json'
import translationCS from '../../../../../i18n/cs_CZ/cs_CZ.json'
import translationDA from '../../../../../i18n/da_DK/da_DK.json'
import translationDE from '../../../../../i18n/de_DE/de_DE.json'
import translationEL from '../../../../../i18n/el_GR/el_GR.json'
import translationEN from '../../../../../i18n/en_US/en_US.json'
import translationES from '../../../../../i18n/es_ES/es_ES.json'
import translationET from '../../../../../i18n/et_EE/et_EE.json'
import translationFI from '../../../../../i18n/fi_FI/fi_FI.json'
import translationFRCA from '../../../../../i18n/fr_CA/fr_CA.json'
import translationFR from '../../../../../i18n/fr_FR/fr_FR.json'
import translationHE from '../../../../../i18n/he_IL/he_IL.json'
import translationHI from '../../../../../i18n/hi_IN/hi_IN.json'
import translationHR from '../../../../../i18n/hr_HR/hr_HR.json'
import translationHU from '../../../../../i18n/hu_HU/hu_HU.json'
import translationIT from '../../../../../i18n/it_IT/it_IT.json'
import translationJA from '../../../../../i18n/ja_JP/ja_JP.json'
import translationKO from '../../../../../i18n/ko_KR/ko_KR.json'
import translationLT from '../../../../../i18n/lt_LT/lt_LT.json'
import translationLV from '../../../../../i18n/lv_LV/lv_LV.json'
import translationMS from '../../../../../i18n/ms_ID/ms_ID.json'
import translationNL from '../../../../../i18n/nl_NL/nl_NL.json'
import translationNO from '../../../../../i18n/no_NO/no_NO.json'
import translationPL from '../../../../../i18n/pl_PL/pl_PL.json'
import translationPTBR from '../../../../../i18n/pt_BR/pt_BR.json'
import translationPT from '../../../../../i18n/pt_PT/pt_PT.json'
import translationRO from '../../../../../i18n/ro_RO/ro_RO.json'
import translationRU from '../../../../../i18n/ru_RU/ru_RU.json'
import translationSQ from '../../../../../i18n/sq_AL/sq_AL.json'
import translationSV from '../../../../../i18n/sv_SE/sv_SE.json'
import translationTH from '../../../../../i18n/th_TH/th_TH.json'
import translationTR from '../../../../../i18n/tr_TR/tr_TR.json'
import translationVI from '../../../../../i18n/vi_VN/vi_VN.json'
import translationZHCN from '../../../../../i18n/zh_CN/zh_CN.json'
import translationZHHK from '../../../../../i18n/zh_HK/zh_HK.json'

export const DEFAULT_LANGUAGE = 'en'
export const SUPPORTED_LANGUAGES = [
  'en',
  'fr',
  'de',
  'es',
  'pt',
  'nl',
  'it',
  'sv',
  'el',
  'pl',
  'ms',
  'vi',
  'ko',
  'ro',
  'hu',
  'bg',
  'frCA',
  'ptBR',
  'trTR',
  'daDK',
  'noNO',
  'ruRU',
  'fiFI',
  'sqAL',
  'zhHK',
  'hrHR',
  'csCZ',
  'etEE',
  'heIL',
  'hiIN',
  'jaJP',
  'lvLV',
  'ltLT',
  'zhCN',
  'thTH',
]

const resources = {
  fr: {
    translation: translationFR,
    locale: frLocale,
  },
  en: {
    translation: translationEN,
    locale: enLocale,
  },
  de: {
    translation: translationDE,
    locale: deLocale,
  },
  es: {
    translation: translationES,
    locale: esLocale,
  },
  pt: {
    translation: translationPT,
    locale: ptLocale,
  },
  nl: {
    translation: translationNL,
    locale: nlLocale,
  },
  it: {
    translation: translationIT,
    locale: itLocale,
  },
  sv: {
    translation: translationSV,
    locale: svLocale,
  },
  el: {
    translation: translationEL,
    locale: elLocale,
  },
  pl: {
    translation: translationPL,
    locale: plLocale,
  },
  ms: {
    translation: translationMS,
    locale: msLocale,
  },
  vi: {
    translation: translationVI,
    locale: viLocale,
  },
  ko: {
    translation: translationKO,
    locale: koLocale,
  },
  ro: {
    translation: translationRO,
    locale: roLocale,
  },
  hu: {
    translation: translationHU,
    locale: huLocale,
  },
  bg: {
    translation: translationBG,
    locale: bgLocale,
  },
  frCA: {
    translation: translationFRCA,
    locale: frCALocale,
  },
  ptBR: {
    translation: translationPTBR,
    locale: ptBRLocale,
  },
  trTR: {
    translation: translationTR,
    locale: trLocale,
  },
  daDK: {
    translation: translationDA,
    locale: daLocale,
  },
  noNO: {
    translation: translationNO,
    locale: noLocale,
  },
  ruRU: {
    translation: translationRU,
    locale: ruLocale,
  },
  fiFI: {
    translation: translationFI,
    locale: fiLocale,
  },
  sqAL: {
    translation: translationSQ,
    locale: sqLocale,
  },
  zhHK: {
    translation: translationZHHK,
    locale: zhHKLocale,
  },
  hrHR: {
    translation: translationHR,
    locale: hrLocale,
  },
  csCZ: {
    translation: translationCS,
    locale: csLocale,
  },
  etEE: {
    translation: translationET,
    locale: etLocale,
  },
  heIL: {
    translation: translationHE,
    locale: heLocale,
  },
  hiIN: {
    translation: translationHI,
    locale: hiLocale,
  },
  jaJP: {
    translation: translationJA,
    locale: jaLocale,
  },
  lvLV: {
    translation: translationLV,
    locale: lvLocale,
  },
  ltLT: {
    translation: translationLT,
    locale: ltLocale,
  },
  zhCN: {
    translation: translationZHCN,
    locale: zhCNLocale,
  },
  thTH: {
    translation: translationTH,
    locale: thLocale,
  },
} as const

export type ResourcesKeys =
  | 'fr'
  | 'en'
  | 'de'
  | 'pt'
  | 'es'
  | 'nl'
  | 'it'
  | 'sv'
  | 'el'
  | 'pl'
  | 'ms'
  | 'vi'
  | 'ko'
  | 'ro'
  | 'hu'
  | 'bg'
  | 'frCA'
  | 'ptBR'
  | 'trTR'
  | 'daDK'
  | 'noNO'
  | 'ruRU'
  | 'fiFI'
  | 'sqAL'
  | 'zhHK'
  | 'hrHR'
  | 'csCZ'
  | 'etEE'
  | 'heIL'
  | 'hiIN'
  | 'jaJP'
  | 'lvLV'
  | 'ltLT'
  | 'zhCN'
  | 'thTH'

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // debug: process.env.NODE_ENV === 'development',
    resources,
    supportedLngs: SUPPORTED_LANGUAGES,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format(value, rawFormat, lng) {
        const [format] = (rawFormat || '').split(',').map((v) => v.trim())

        if (format === 'uppercase') {
          return value.toUpperCase()
        }
        if (value instanceof Date) {
          return formatDate(value, format || '', {
            locale: resources[(lng || 'en') as ResourcesKeys].locale,
          })
        }
        if (format === 'price') {
          return Intl.NumberFormat(lng, {
            style: 'currency',
            currency: value.currency || 'EUR',
          }).format(value.price / 100)
        }
        if (format === 'date') {
          return Intl.DateTimeFormat(
            resources[(lng || 'en') as ResourcesKeys].locale.code,
            value.format,
          ).format(value.date)
        }
        return value
      },
    },
    fallbackLng: DEFAULT_LANGUAGE,
  })
  .then(() => {
    // Override the plural resolver to format the code correctly for the Intl.PluralRules constructor
    const current = i18next.services.pluralResolver
    i18next.services.pluralResolver.getRule = (code: string, options = { ordinal: false }) => {
      if (current.shouldUseIntlApi()) {
        try {
          // Need to handle dev language code because it's used in the codebase of i18n but not supported by Intl.PluralRules
          let cleanedCode = code === 'dev' ? 'en' : code
          const match = code.match(/^([a-z]{2})([A-Z]{2})$/)
          if (match) {
            cleanedCode = `${match[1]}-${match[2]}`
          }
          const type = options.ordinal ? 'ordinal' : 'cardinal'
          const cacheKey = JSON.stringify({ cleanedCode, type })
          if (cacheKey in current.pluralRulesCache) {
            return current.pluralRulesCache[cacheKey]
          }
          const rule = new Intl.PluralRules(cleanedCode, { type })
          current.pluralRulesCache[cacheKey] = rule
          return rule
        } catch (err) {
          return
        }
      }

      return (
        current.rules[code] || current.rules[current.languageUtils.getLanguagePartFromCode(code)]
      )
    }
  })

export const i18n = i18next
