type VerticalBarChartProps = {
  color?: string
  size?: number
}

export const VerticalBarChart: React.FC<VerticalBarChartProps> = ({
  size = 24,
  color = '#000',
}) => (
  <svg
    id="bar chart alt Vertical"
    width={size}
    height={size}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.50027 12.1172H9.50064V20.1184H4.50027C3.94759 20.1184 3.5 19.6698 3.5 19.1181V13.1175C3.5 12.5648 3.94759 12.1172 4.50027 12.1172Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4994 8.11816H15.499V20.1185H20.4994C21.0521 20.1185 21.4997 19.6709 21.4997 19.1192V9.11843C21.4997 8.56576 21.0521 8.11816 20.4994 8.11816Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5004 4.11816H10.5003C9.94759 4.11816 9.5 4.56576 9.5 5.11843V20.1195H15.5006V5.11843C15.5006 4.56576 15.053 4.11816 14.5004 4.11816Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
