import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Printer: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="printer 2"
      width="24"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.58049 7.72461C5.15713 7.72461 4 8.88391 4 10.2954V14.3099C4 15.7288 5.15584 16.8806 6.58049 16.8806H7.66049C8.0747 16.8806 8.41049 17.2164 8.41049 17.6306C8.41049 18.0448 8.0747 18.3806 7.66049 18.3806H6.58049C4.33313 18.3806 2.5 16.5629 2.5 14.3099V10.2954C2.5 8.05234 4.33184 6.22461 6.58049 6.22461H17.9292C20.1807 6.22461 22 8.0552 22 10.2954V14.3099C22 16.5601 20.1795 18.3806 17.9292 18.3806H16.8765C16.4623 18.3806 16.1265 18.0448 16.1265 17.6306C16.1265 17.2164 16.4623 16.8806 16.8765 16.8806H17.9292C19.351 16.8806 20.5 15.7316 20.5 14.3099V10.2954C20.5 8.88105 19.3497 7.72461 17.9292 7.72461H6.58049Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.66309 14.0586C8.0773 14.0586 8.41309 14.3944 8.41309 14.8086V18.5789C8.41309 19.3137 9.00843 19.9091 9.7433 19.9091H14.758C15.4917 19.9091 16.0872 19.314 16.0872 18.5789V14.8086C16.0872 14.3944 16.423 14.0586 16.8372 14.0586C17.2515 14.0586 17.5872 14.3944 17.5872 14.8086V18.5789C17.5872 20.1419 16.3206 21.4091 14.758 21.4091H9.7433C8.18001 21.4091 6.91309 20.1422 6.91309 18.5789V14.8086C6.91309 14.3944 7.24887 14.0586 7.66309 14.0586Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.03711 14.8086C6.03711 14.3944 6.3729 14.0586 6.78711 14.0586H17.7146C18.1288 14.0586 18.4646 14.3944 18.4646 14.8086C18.4646 15.2228 18.1288 15.5586 17.7146 15.5586H6.78711C6.3729 15.5586 6.03711 15.2228 6.03711 14.8086Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.041 10.5508C15.041 10.1366 15.3768 9.80078 15.791 9.80078H17.9111C18.3253 9.80078 18.6611 10.1366 18.6611 10.5508C18.6611 10.965 18.3253 11.3008 17.9111 11.3008H15.791C15.3768 11.3008 15.041 10.965 15.041 10.5508Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.74428 4.08984C9.00964 4.08984 8.41406 4.68594 8.41406 5.42006V6.98849C8.41406 7.40271 8.07828 7.73849 7.66406 7.73849C7.24985 7.73849 6.91406 7.40271 6.91406 6.98849V5.42006C6.91406 3.85797 8.18076 2.58984 9.74428 2.58984H14.759C16.3218 2.58984 17.5882 3.8582 17.5882 5.42006V6.98849C17.5882 7.40271 17.2524 7.73849 16.8382 7.73849C16.424 7.73849 16.0882 7.40271 16.0882 6.98849V5.42006C16.0882 4.68571 15.4924 4.08984 14.759 4.08984H9.74428Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
