import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const List: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Bulleted list"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1257 16.9941H8.12567C7.57267 16.9941 7.12567 17.4411 7.12567 17.9941C7.12567 18.5471 7.57267 18.9941 8.12567 18.9941H20.1257C20.6787 18.9941 21.1257 18.5471 21.1257 17.9941C21.1257 17.4411 20.6787 16.9941 20.1257 16.9941Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1257 10.9941H8.12567C7.57267 10.9941 7.12567 11.4411 7.12567 11.9941C7.12567 12.5471 7.57267 12.9941 8.12567 12.9941H20.1257C20.6787 12.9941 21.1257 12.5471 21.1257 11.9941C21.1257 11.4411 20.6787 10.9941 20.1257 10.9941Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12567 6.99414H20.1257C20.6787 6.99414 21.1257 6.54714 21.1257 5.99414C21.1257 5.44114 20.6787 4.99414 20.1257 4.99414H8.12567C7.57267 4.99414 7.12567 5.44114 7.12567 5.99414C7.12567 6.54714 7.57267 6.99414 8.12567 6.99414Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.13539 4.73438C3.44039 4.73438 2.87439 5.30338 2.87439 6.00437C2.87439 6.69837 3.44039 7.26338 4.13539 7.26338C4.83039 7.26338 5.39739 6.69837 5.39739 6.00437C5.39739 5.30338 4.83039 4.73438 4.13539 4.73438Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.13539 10.875C3.44039 10.875 2.87439 11.44 2.87439 12.135C2.87439 12.829 3.44039 13.395 4.13539 13.395C4.83039 13.395 5.39739 12.829 5.39739 12.135C5.39739 11.44 4.83039 10.875 4.13539 10.875Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.13539 16.7344C3.44039 16.7344 2.87439 17.3034 2.87439 18.0044C2.87439 18.6984 3.44039 19.2634 4.13539 19.2634C4.83039 19.2634 5.39739 18.6984 5.39739 18.0044C5.39739 17.3034 4.83039 16.7344 4.13539 16.7344Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
