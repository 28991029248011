import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const SendMessage: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8766 3.91552C20.3226 3.36052 19.5426 3.16252 18.7896 3.38352L4.0166 7.73152C3.2056 7.97152 2.6326 8.64152 2.5196 9.48152C2.4056 10.3215 2.7826 11.1205 3.5026 11.5655L8.6256 14.7349C8.78418 14.833 8.98951 14.8085 9.12058 14.6758L15.2696 8.45252C15.5606 8.15752 16.0346 8.15552 16.3296 8.44552C16.6246 8.73752 16.6266 9.21152 16.3356 9.50652L10.1691 15.7473C10.0397 15.8784 10.0164 16.0809 10.1129 16.2379L13.2536 21.3495C13.6466 21.9875 14.3136 22.3565 15.0416 22.3565C15.1376 22.3565 15.2346 22.3505 15.3326 22.3365C16.1716 22.2245 16.8406 21.6485 17.0776 20.8345L21.4116 6.01052C21.6336 5.25452 21.4336 4.47152 20.8766 3.91552Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
