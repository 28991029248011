import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Book: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.17459 18.3741C7.55059 18.5454 9.61913 19.5174 10.8383 20.2238C11.5592 20.6412 12.4408 20.6412 13.1617 20.2238C14.3809 19.5174 16.4494 18.5454 18.8254 18.3741C20.0406 18.2866 21 17.3136 21 16.0954V5.75371C21 4.38182 19.7994 3.33004 18.4362 3.4789C16.2246 3.72117 14.3137 4.62507 13.1617 5.2935C12.4408 5.7109 11.5592 5.7109 10.8383 5.2935C9.68627 4.62507 7.77535 3.72117 5.56378 3.4789C4.19968 3.33004 3 4.38182 3 5.75371V16.0954C3 17.3136 3.95935 18.2866 5.17459 18.3741Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        stroke="currentColor"
      />
      <path
        d="M12 5.87891V20.4803"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M8.42749 9.7927L6.24219 8.92578M8.42749 14.2898L6.24219 13.4229"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M15.3848 9.79661L17.5701 8.92969M15.3848 14.2928L17.5701 13.4259"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  </SvgIcon>
)
