import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const CodeBlock: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Code"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.647 10.949L13.998 13.598C13.851 13.745 13.66 13.818 13.467 13.818C13.275 13.818 13.084 13.745 12.937 13.598C12.644 13.305 12.644 12.831 12.937 12.538L15.056 10.419L12.937 8.3C12.644 8.007 12.644 7.532 12.937 7.239C13.23 6.946 13.705 6.946 13.998 7.239L16.647 9.888C16.94 10.181 16.94 10.656 16.647 10.949ZM11.063 15.7C11.356 15.993 11.356 16.468 11.063 16.761C10.917 16.907 10.725 16.98 10.533 16.98C10.341 16.98 10.149 16.907 10.002 16.761L7.353 14.111C7.06 13.818 7.06 13.344 7.353 13.051L10.002 10.401C10.295 10.108 10.77 10.108 11.063 10.401C11.356 10.694 11.356 11.169 11.063 11.462L8.944 13.581L11.063 15.7ZM16.217 2.5H7.783C4.623 2.5 2.5 4.72 2.5 8.026V15.973C2.5 19.279 4.623 21.5 7.783 21.5H16.216C19.376 21.5 21.5 19.279 21.5 15.973V8.026C21.5 4.72 19.376 2.5 16.217 2.5Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
