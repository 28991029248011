import { Slide, SlideProps, useScrollTrigger } from '@mui/material'
import { ReactNode, RefObject } from 'react'

type HideOnScrollProps = {
  window?: () => Window
  parentRef: RefObject<HTMLDivElement>
  children: ReactNode
} & SlideProps

export function HideOnScroll({ children, parentRef, ...slideProps }: HideOnScrollProps) {
  const trigger = useScrollTrigger({
    target: parentRef.current || undefined,
  })

  return (
    <Slide appear={false} direction="down" in={!trigger} {...slideProps}>
      {children}
    </Slide>
  )
}
