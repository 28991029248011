import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Italic: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Italic"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7547 6H17C17.553 6 18 5.553 18 5C18 4.447 17.553 4 17 4H11.001C10.448 4 10.001 4.447 10.001 5C10.001 5.553 10.448 6 11.001 6H12.6742L9.2456 18H7C6.447 18 6 18.447 6 19C6 19.553 6.447 20 7 20H9.96357C9.97604 20.0005 9.9885 20.0007 10.001 20.0007C10.0135 20.0007 10.0261 20.0005 10.0386 20H13C13.553 20 14 19.553 14 19C14 18.447 13.553 18 13 18H11.3262L14.7547 6Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
