import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const FormatLink: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Link"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4317 15.0506C17.1767 15.0506 16.9207 14.9536 16.7257 14.7586C16.3347 14.3686 16.3327 13.7366 16.7237 13.3446L17.7497 12.3146C18.5557 11.5096 18.9987 10.4336 18.9997 9.28359C18.9997 8.13259 18.5567 7.05459 17.7507 6.24759C16.0777 4.57759 13.3617 4.57759 11.6907 6.24759L10.6637 7.27759C10.2747 7.66959 9.64168 7.66859 9.24968 7.27959C8.85868 6.88959 8.85868 6.25559 9.24768 5.86459L10.2757 4.83459C12.7247 2.38359 16.7117 2.38359 19.1647 4.83259C20.3497 6.01859 21.0007 7.59959 20.9997 9.28459C20.9987 10.9696 20.3467 12.5466 19.1647 13.7286L18.1397 14.7566C17.9447 14.9526 17.6887 15.0506 17.4317 15.0506Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.28331 21.0042C7.6743 21.0042 6.06431 20.3922 4.83831 19.1662C3.65331 17.9822 3.00031 16.4022 3.00031 14.7192C3.00031 13.0362 3.65331 11.4572 4.83831 10.2732L5.85731 9.25223C6.24831 8.86123 6.88131 8.86123 7.27131 9.25223C7.6623 9.64223 7.6623 10.2752 7.27131 10.6662L6.25231 11.6872C5.44431 12.4942 5.00031 13.5712 5.00031 14.7192C5.00031 15.8672 5.44431 16.9442 6.25231 17.7522C7.92431 19.4222 10.6423 19.4222 12.3123 17.7522L13.3333 16.7322C13.7233 16.3412 14.3563 16.3412 14.7473 16.7322C15.1373 17.1222 15.1373 17.7552 14.7473 18.1462L13.7263 19.1662C12.5023 20.3912 10.8923 21.0042 9.28331 21.0042Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
