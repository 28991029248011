/**
 * From https://github.com/sindresorhus/type-fest/
 * Matches a JSON object.
 * This type can be useful to enforce some input to be JSON-compatible or as a super-type to be extended from.
 */
export type JsonObject = { [Key in string]?: JsonValue }

/**
 * From https://github.com/sindresorhus/type-fest/
 * Matches a JSON array.
 */
export type JsonArray = Array<JsonValue>

/**
 * From https://github.com/sindresorhus/type-fest/
 * Matches any valid JSON value.
 */
export type JsonValue = string | number | boolean | null | JsonObject | JsonArray

/**
 * Type guards helpers
 */
export const jsonValueHelper = {
  isArray(value: JsonValue | undefined): value is JsonArray {
    return !!value && Array.isArray(value)
  },
  isObject(value: JsonValue | undefined): value is JsonObject {
    return !!value && !Array.isArray(value) && typeof value === 'object'
  },
  isString(value: JsonValue | undefined): value is string {
    return typeof value === 'string'
  },
  isBoolean(value: JsonValue | undefined): value is boolean {
    return typeof value === 'boolean'
  },
  isNumber(value: JsonValue | undefined): value is number {
    return typeof value === 'number'
  },
  toArray(value: JsonValue | undefined) {
    return this.isArray(value) ? value : []
  },
  toObject(value: JsonValue | undefined) {
    return this.isObject(value) ? value : {}
  },
  toString(value: JsonValue | undefined) {
    return this.isString(value) ? value : ''
  },
  toBoolean(value: JsonValue | undefined) {
    return this.isBoolean(value) ? value : false
  },
  toNumber(value: JsonValue | undefined) {
    return this.isNumber(value) ? value : 0
  },
}
