import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Winking: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M12 21C16.9709 21 21 16.9699 21 12C21 7.02908 16.9709 3 12 3C7.02908 3 3 7.02908 3 12C3 16.9699 7.02908 21 12 21Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M8.41016 8.51953H9.99124"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M15.0305 8.50533V8.45377M15.0238 8.30078C14.8998 8.30078 14.7988 8.40178 14.7998 8.52578C14.7998 8.64978 14.9008 8.75078 15.0248 8.75078C15.1488 8.75078 15.2498 8.64978 15.2498 8.52578C15.2498 8.40078 15.1488 8.30078 15.0238 8.30078Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8695 12.9375C14.8695 14.6402 13.5851 16.0209 12.0011 16.0209C10.4171 16.0209 9.13281 14.6402 9.13281 12.9375H14.8695Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
