import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Scale: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Scale"
      width="24"
      height="24"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1943 5.54684C13.1943 4.76384 12.5613 4.13086 11.7783 4.13086C10.9963 4.13086 10.3633 4.76384 10.3633 5.54684C10.3633 6.32784 10.9963 6.96185 11.7783 6.96185C12.5613 6.96185 13.1943 6.32784 13.1943 5.54684Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3871 5.77795L3.28906 6.96097M20.2711 4.12695L13.1731 5.30997L20.2711 4.12695Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.64062 20.4395H18.9186"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.84375 17.8399H14.7137M11.7787 17.8399V6.96289V17.8399Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 14.2511L5.66699 8.78711L8.36499 14.2511C6.79899 16.4041 4.016 16.2261 3 14.2511Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6348 12.1925L18.3018 6.72852L20.9998 12.1925C19.4338 14.3445 16.6508 14.1675 15.6348 12.1925Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
