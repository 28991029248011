import { Box, useTheme } from '@mui/material'
import { ArrowClockwise } from 'phosphor-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function Countdown() {
  const [timer, setTimer] = useState<number>(300)
  const { t } = useTranslation()
  const theme = useTheme()

  useEffect(() => {
    setInterval(() => {
      setTimer((timer) => timer - 1)
    }, 1000)
  }, [setTimer])

  useEffect(() => {
    if (!timer) {
      window.location.reload()
    }
  }, [timer])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', color: '#C9CFD9', justifyContent: 'center' }}>
      <ArrowClockwise />
      <Box sx={{ marginLeft: theme.spacing(1) }}>
        {`${t('maintenance.countdown', 'We’ll refresh the page for you in')} ${new Date(
          timer * 1000,
        )
          .toISOString()
          .substring(14, 19)}`}
      </Box>
    </Box>
  )
}

export default Countdown
