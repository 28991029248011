import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

export const QuestionCircle: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="messages question"
      width="24"
      height="24"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.30469 11.0773C9.30469 9.44425 10.614 8.125 12.2486 8.125C13.8732 8.125 15.1934 9.44208 15.1934 11.0773C15.1934 12.2334 14.5312 13.0495 13.6795 13.5223C13.6269 13.5524 13.5771 13.5805 13.5296 13.6073C13.2859 13.7449 13.1057 13.8467 12.9585 13.9797C12.8179 14.1066 12.7721 14.2061 12.7739 14.3451C12.7793 14.7593 12.4479 15.0994 12.0337 15.1048C11.6195 15.1102 11.2794 14.7788 11.274 14.3646C11.2651 13.683 11.5859 13.198 11.9532 12.8664C12.2369 12.6102 12.5894 12.4134 12.8291 12.2796C12.8692 12.2572 12.9061 12.2366 12.9391 12.2178L12.9485 12.2124L12.9485 12.2125C13.4421 11.9394 13.6934 11.568 13.6934 11.0773C13.6934 10.2692 13.0435 9.625 12.2486 9.625C11.4481 9.625 10.8047 10.267 10.8047 11.0773C10.8047 11.4915 10.4689 11.8273 10.0547 11.8273C9.64047 11.8273 9.30469 11.4915 9.30469 11.0773ZM12.0253 15.8533C12.4395 15.8533 12.7753 16.1891 12.7753 16.6033V16.6102C12.7753 17.0244 12.4395 17.3602 12.0253 17.3602C11.6111 17.3602 11.2753 17.0244 11.2753 16.6102V16.6033C11.2753 16.1891 11.6111 15.8533 12.0253 15.8533Z"
        fill="currentColor"
      />
      <path
        d="M12.2504 22.5035H12.2174C11.3984 22.5035 7.98638 22.5035 5.61738 22.5035H4.9994C4.70604 22.5024 4.41841 22.4219 4.16713 22.2706C3.91584 22.1192 3.71026 21.9025 3.57222 21.6437C3.43417 21.3848 3.36884 21.0934 3.38313 20.8004C3.39742 20.5074 3.49081 20.2237 3.65339 19.9795C3.87339 19.6485 4.15339 19.2435 4.38139 18.9275C4.40534 18.8928 4.41892 18.8519 4.42051 18.8098C4.42211 18.7676 4.41166 18.7259 4.39039 18.6895C4.01668 18.0846 3.68103 17.4571 3.38539 16.8105C2.55859 14.9938 2.30414 12.9685 2.65583 11.0037C3.00753 9.03888 3.94872 7.22761 5.35438 5.81049C6.49279 4.68143 7.89168 3.85003 9.42753 3.38969C10.9634 2.92935 12.589 2.85423 14.1607 3.17097C15.7325 3.48771 17.2021 4.18656 18.4397 5.20582C19.6774 6.22508 20.6451 7.53339 21.2574 9.01524C21.8697 10.4971 22.1076 12.1069 21.9504 13.7025C21.7931 15.2981 21.2453 16.8305 20.3555 18.1643C19.4657 19.4981 18.2612 20.5923 16.8484 21.3503C15.4355 22.1083 13.8578 22.5067 12.2544 22.5105L12.2504 22.5035ZM12.2224 21.0035H12.2504C13.4551 21.0023 14.6449 20.7373 15.7362 20.2271C16.8275 19.717 17.7939 18.974 18.5674 18.0505C19.7704 16.5857 20.4425 14.757 20.4747 12.8618C20.5068 10.9666 19.897 9.11625 18.7444 7.61149C18.0257 6.69082 17.1195 5.9335 16.0858 5.38975C15.0522 4.84601 13.9147 4.52827 12.7489 4.45763C11.5831 4.38699 10.4155 4.56506 9.32377 4.98001C8.23202 5.39497 7.24098 6.03733 6.41639 6.86449C5.22333 8.06991 4.42594 9.61028 4.13044 11.2803C3.83495 12.9504 4.05539 14.6709 4.7624 16.2125C5.04071 16.8038 5.35082 17.3797 5.69139 17.9375C5.85408 18.2224 5.93248 18.5476 5.91743 18.8753C5.90238 19.203 5.79451 19.5197 5.6064 19.7885C5.3874 20.0965 5.11538 20.4885 4.90638 20.8065C4.89197 20.8255 4.88355 20.8484 4.88212 20.8723C4.8807 20.8961 4.88634 20.9199 4.89839 20.9405C4.93239 21.0025 4.97039 20.9855 4.99839 21.0035H5.54039C7.98639 21.0035 11.4024 21.0035 12.2224 21.0035Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
