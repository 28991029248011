import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const UserStar: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M10.5425 12.8311C13.1386 12.8311 15.2432 10.7265 15.2432 8.1304C15.2432 5.53427 13.1386 3.42969 10.5425 3.42969C7.94638 3.42969 5.8418 5.53427 5.8418 8.1304C5.8418 10.7265 7.94638 12.8311 10.5425 12.8311Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M4.12717 19.4602C4.01632 18.822 4.0621 18.1606 4.0621 17.516C4.0621 15.0866 6.03151 13.1172 8.46089 13.1172H12.7442C13.0707 13.1172 13.3889 13.1528 13.6951 13.2202"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2382 14.5574L18.0317 16.1535C18.071 16.2339 18.1484 16.2902 18.2369 16.3023L20.013 16.5598C20.0855 16.5688 20.1498 16.6071 20.1941 16.6644C20.2765 16.772 20.2645 16.9249 20.1649 17.0174L18.8776 18.2625C18.8122 18.3239 18.783 18.4144 18.8001 18.5019L19.1089 20.2589C19.13 20.4047 19.0304 20.5405 18.8856 20.5646C18.8253 20.5737 18.7629 20.5646 18.7086 20.5375L17.1266 19.7088C17.0471 19.6655 16.9516 19.6655 16.8721 19.7088L15.279 20.5415C15.1453 20.6099 14.9823 20.5596 14.9099 20.4289C14.8828 20.3766 14.8727 20.3162 14.8817 20.2569L15.1905 18.5009C15.2056 18.4134 15.1764 18.3239 15.1131 18.2615L13.8187 17.0164C13.7141 16.9118 13.7141 16.7408 13.8187 16.6362C13.8619 16.597 13.9162 16.5698 13.9736 16.5598L15.7507 16.3013C15.8392 16.2882 15.9157 16.2319 15.9559 16.1515L16.7484 14.5574C16.7806 14.493 16.8369 14.4427 16.9053 14.4206C16.9737 14.3974 17.0491 14.4025 17.1145 14.4347C17.1678 14.4618 17.211 14.504 17.2382 14.5574Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
