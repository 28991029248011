import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Refresh: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 4L14 8H17C17 11.31 14.31 14 11 14C9.99 14 9.03 13.75 8.2 13.3L6.74 14.76C7.97 15.54 9.43 16 11 16C15.42 16 19 12.42 19 8H22L18 4ZM5 8C5 4.69 7.69 2 11 2C12.01 2 12.97 2.25 13.8 2.7L15.26 1.24C14.03 0.46 12.57 0 11 0C6.58 0 3 3.58 3 8H0L4 12L8 8H5Z"
        fillOpacity={props.fillOpacity}
        fill={props.fill}
      />
    </svg>
  </SvgIcon>
)
