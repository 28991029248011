import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const QuestionMark: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.709 12.795L13.568 12.874C12.92 13.236 12.691 13.422 12.696 13.74C12.701 14.154 12.37 14.494 11.956 14.5H11.946C11.536 14.5 11.201 14.171 11.196 13.76C11.179 12.492 12.216 11.912 12.835 11.566L12.976 11.486C13.545 11.173 13.829 10.739 13.829 10.193C13.829 9.29 13.094 8.556 12.191 8.556C11.272 8.556 10.552 9.275 10.552 10.193C10.552 10.607 10.216 10.943 9.802 10.943C9.388 10.943 9.052 10.607 9.052 10.193C9.052 8.463 10.46 7.056 12.191 7.056C13.921 7.056 15.329 8.463 15.329 10.193C15.329 11.296 14.753 12.22 13.709 12.795ZM11.947 16.96C11.533 16.96 11.197 16.639 11.197 16.225V16.195C11.197 15.781 11.533 15.445 11.947 15.445C12.361 15.445 12.697 15.781 12.697 16.195C12.697 16.609 12.361 16.96 11.947 16.96ZM12 2.5C6.761 2.5 2.5 6.762 2.5 12C2.5 17.238 6.761 21.5 12 21.5C17.238 21.5 21.5 17.238 21.5 12C21.5 6.762 17.238 2.5 12 2.5Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
