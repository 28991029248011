import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Undo: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="undo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0352 3.96967C11.3281 4.26256 11.3281 4.73744 11.0352 5.03033L8.31554 7.75H11.4829C17.4212 7.75 20.4658 14.8653 16.3656 19.1607L15.5474 20.0179C15.2614 20.3175 14.7866 20.3285 14.487 20.0425C14.1874 19.7565 14.1764 19.2818 14.4624 18.9821L15.2806 18.125C18.4696 14.7841 16.1016 9.25 11.4829 9.25H8.31554L11.0352 11.9697C11.3281 12.2626 11.3281 12.7374 11.0352 13.0303C10.7423 13.3232 10.2674 13.3232 9.97455 13.0303L5.97455 9.03033C5.68166 8.73744 5.68166 8.26256 5.97455 7.96967L9.97455 3.96967C10.2674 3.67678 10.7423 3.67678 11.0352 3.96967Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
