import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Person: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M3.64258 5.78273C3.64258 7.31959 4.88845 8.56547 6.42531 8.56547C7.96217 8.56547 9.20805 7.31959 9.20805 5.78273C9.20805 4.24587 7.96217 3 6.42531 3C4.88845 3 3.64258 4.24587 3.64258 5.78273Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M9.9434 17.8749L8.49749 14.4072C7.97679 13.1584 7.71643 12.534 7.3974 12.2809C6.824 11.826 6.01306 11.8251 5.43864 12.2787C3.37792 13.9061 2.89716 21 5.43864 21H7.86058C8.76502 21 9.21724 21 9.52599 20.8611C10.078 20.6128 10.4394 20.0706 10.4561 19.4655C10.4655 19.1271 10.2914 18.7097 9.9434 17.8749Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M20.3569 5.78273C20.3569 7.31959 19.111 8.56547 17.5741 8.56547C16.0373 8.56547 14.7914 7.31959 14.7914 5.78273C14.7914 4.24587 16.0373 3 17.5741 3C19.111 3 20.3569 4.24587 20.3569 5.78273Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M14.0561 17.8749L15.502 14.4072C16.0227 13.1584 16.283 12.534 16.6021 12.2809C17.1755 11.826 17.9864 11.8251 18.5608 12.2787C20.6216 13.9061 21.1023 21 18.5608 21H16.1389C15.2345 21 14.7822 21 14.4735 20.8611C13.9214 20.6128 13.56 20.0706 13.5433 19.4655C13.534 19.1271 13.708 18.7097 14.0561 17.8749Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
