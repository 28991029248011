import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const TitleH1: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="h1"
      width="24"
      height="24"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7279 9.27586C21.0082 9.39195 21.1909 9.66543 21.1909 9.96877V18.4393C21.1909 18.8535 20.8551 19.1893 20.4409 19.1893C20.0267 19.1893 19.6909 18.8535 19.6909 18.4393V11.7794L18.8536 12.6167C18.5607 12.9096 18.0858 12.9096 17.7929 12.6167C17.5 12.3238 17.5 11.849 17.7929 11.5561L19.9106 9.43844C20.1251 9.22394 20.4476 9.15978 20.7279 9.27586Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.55859 4.98438C4.97281 4.98438 5.30859 5.32016 5.30859 5.73438V11.3373H12.2792V5.73438C12.2792 5.32016 12.6149 4.98438 13.0292 4.98438C13.4434 4.98438 13.7792 5.32016 13.7792 5.73438V18.4402C13.7792 18.8544 13.4434 19.1902 13.0292 19.1902C12.6149 19.1902 12.2792 18.8544 12.2792 18.4402V12.8373H5.30859V18.4402C5.30859 18.8544 4.97281 19.1902 4.55859 19.1902C4.14438 19.1902 3.80859 18.8544 3.80859 18.4402V5.73438C3.80859 5.32016 4.14438 4.98438 4.55859 4.98438Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
