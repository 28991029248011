import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Redo: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="redo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9646 3.96967C13.2575 3.67678 13.7323 3.67678 14.0252 3.96967L18.0252 7.96967C18.3181 8.26256 18.3181 8.73744 18.0252 9.03033L14.0252 13.0303C13.7323 13.3232 13.2575 13.3232 12.9646 13.0303C12.6717 12.7374 12.6717 12.2626 12.9646 11.9697L15.6842 9.25H12.5169C7.89823 9.25 5.53019 14.7841 8.71924 18.125L9.53742 18.9821C9.82343 19.2818 9.81239 19.7565 9.51276 20.0425C9.21314 20.3285 8.73839 20.3175 8.45239 20.0179L7.6342 19.1607C3.534 14.8653 6.57863 7.75 12.5169 7.75H15.6842L12.9646 5.03033C12.6717 4.73744 12.6717 4.26256 12.9646 3.96967Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
