import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const TitleH2: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="h2"
      width="24"
      height="24"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 4.98438C3.91421 4.98438 4.25 5.32016 4.25 5.73438V11.3373H11.2206V5.73438C11.2206 5.32016 11.5563 4.98438 11.9706 4.98438C12.3848 4.98438 12.7206 5.32016 12.7206 5.73438V18.4402C12.7206 18.8544 12.3848 19.1902 11.9706 19.1902C11.5563 19.1902 11.2206 18.8544 11.2206 18.4402V12.8373H4.25V18.4402C4.25 18.8544 3.91421 19.1902 3.5 19.1902C3.08579 19.1902 2.75 18.8544 2.75 18.4402V5.73438C2.75 5.32016 3.08579 4.98438 3.5 4.98438Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2292 10.7188C18.3898 10.7188 17.708 11.4006 17.708 12.24C17.708 12.6542 17.3722 12.99 16.958 12.99C16.5438 12.99 16.208 12.6542 16.208 12.24C16.208 10.5721 17.5614 9.21875 19.2292 9.21875C20.8971 9.21875 22.2504 10.5721 22.2504 12.24C22.2504 13.0681 22.0235 13.664 21.6984 14.2205C21.2016 15.0706 20.395 15.903 19.7371 16.582C19.5771 16.7471 19.4259 16.9032 19.29 17.0482L18.6896 17.6893H21.5004C21.9147 17.6893 22.2504 18.0251 22.2504 18.4393C22.2504 18.8535 21.9147 19.1893 21.5004 19.1893H16.9596C16.6607 19.1893 16.3903 19.0119 16.2715 18.7376C16.1526 18.4634 16.2079 18.1448 16.4122 17.9266L18.1952 16.0228C18.3716 15.8345 18.5517 15.648 18.7307 15.4627C19.3762 14.7944 20.0079 14.1402 20.4033 13.4637C20.6263 13.082 20.7504 12.7451 20.7504 12.24C20.7504 11.4006 20.0686 10.7188 19.2292 10.7188Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
