import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const ExpiredClock: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Clock close"
      width="24"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1582 12C21.1582 7.02908 17.1291 3 12.1582 3C7.18728 3 3.1582 7.02908 3.1582 12C3.1582 16.9699 7.18728 21 12.1582 21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0787 19.8072L15.9863 15.7148"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0787 15.7148L15.9863 19.8072"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.3125 14.5843L12.4873 12.6861V8.58789"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
