import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Share: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.282 13.9656C8.10255 13.9656 4.86691 15.4116 3.07725 19.3633C3.07725 19.3633 1.53024 8.5174 12.282 8.5174C12.282 8.5174 12.283 6.9189 12.284 5.5432C12.285 4.7707 13.1892 4.3537 13.7785 4.8529L20.6767 10.6963C21.0956 11.0518 21.1105 11.6906 20.7034 12.0591C19.1871 13.4288 15.7791 16.5099 13.7944 18.3036C13.211 18.8305 12.282 18.4135 12.282 17.6271V13.9656Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
