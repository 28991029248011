import { formatDistanceToNow } from 'date-fns'

import { getCurrentLocale } from './locale'

export const formatTimeAgo = (value: unknown, language: string) => {
  try {
    if (typeof value === 'number' || typeof value === 'string' || value instanceof Date) {
      return formatDistanceToNow(new Date(value), {
        addSuffix: true,
        locale: getCurrentLocale(language),
      })
    } else {
      // handle wrong types
      return ''
    }
  } catch {
    // handle parse errors on invalid dates
    return ''
  }
}
