import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const AiBrainNetwork: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63081 13.9441C8.04461 13.9257 8.39496 14.2463 8.41334 14.6601C8.44547 15.3838 8.86703 15.7917 9.42262 15.9467C10.0223 16.1141 10.7137 15.9612 11.1392 15.5483C11.3095 15.383 11.5481 15.3082 11.7824 15.3465C12.0167 15.3848 12.2191 15.5317 12.3279 15.7427C12.5202 16.1153 12.7968 16.2859 13.0797 16.3642C13.3873 16.4495 13.697 16.4205 13.8593 16.3764C14.2215 16.278 14.6007 16.4625 14.7468 16.8082L15.7698 19.2287H16.3377V18.1746C16.3377 17.7776 16.647 17.4494 17.0433 17.4259C19.1119 17.303 19.9423 16.6143 20.2445 16.061C20.4165 15.7458 20.4603 15.4067 20.4154 15.0932C20.3566 14.6832 20.6414 14.3032 21.0514 14.2444C21.4615 14.1857 21.8415 14.4705 21.9002 14.8805C21.986 15.4797 21.9043 16.1511 21.561 16.7798C20.9828 17.8387 19.7708 18.6083 17.8377 18.8576V19.8976C17.8377 20.3219 17.5013 20.7287 17.0105 20.7287H15.3228C14.9747 20.7287 14.6847 20.5143 14.5613 20.2225L13.5873 17.9178C13.3068 17.9257 12.9952 17.8973 12.6792 17.8098C12.2696 17.6963 11.8514 17.4817 11.4962 17.1249C10.731 17.533 9.81736 17.6142 9.0194 17.3915C7.91909 17.0844 6.97924 16.1774 6.91481 14.7266C6.89644 14.3128 7.217 13.9625 7.63081 13.9441ZM15.9427 19.638C15.9428 19.6382 15.9426 19.6378 15.9427 19.638Z"
        fill="currentColor"
      />
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.62007 8.89877C3.96403 9.12955 4.05578 9.59548 3.82499 9.93944C2.78781 11.4852 3.23029 13.2727 4.51846 14.0552C4.93689 14.3093 5.46919 14.4312 5.99499 14.4212C6.52934 14.411 6.97273 14.2669 7.22809 14.0852C7.88975 13.6142 8.2217 12.815 8.17157 12.2888C8.13229 11.8764 8.43472 11.5103 8.84706 11.471C9.25941 11.4317 9.62553 11.7342 9.66481 12.1465C9.77201 13.2719 9.13967 14.5657 8.09788 15.3072C7.53234 15.7098 6.77024 15.9067 6.02361 15.9209C5.26843 15.9353 4.44425 15.7651 3.73976 15.3372C1.64916 14.0674 1.11389 11.2878 2.57939 9.10368C2.81018 8.75972 3.27611 8.66798 3.62007 8.89877Z"
        fill="currentColor"
      />
      <path
        id="Vector_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4401 3.983C11.496 4.39343 11.2085 4.77141 10.7981 4.82723C9.3469 5.02461 8.20007 5.70098 7.43098 6.15457C7.40939 6.1673 7.38811 6.17986 7.36712 6.19222C7.01023 6.40246 6.55048 6.28358 6.34024 5.92668C6.13 5.56979 6.24888 5.11004 6.60577 4.8998C6.6356 4.88223 6.66648 4.86398 6.69839 4.84512C7.46816 4.39019 8.83936 3.57983 10.5959 3.34092C11.0063 3.28509 11.3843 3.57256 11.4401 3.983Z"
        fill="currentColor"
      />
      <path
        id="Vector_4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.22821 9.5735C4.3749 10.0503 4.73404 10.4697 5.46186 10.655C5.86327 10.7573 6.10581 11.1655 6.00359 11.5669C5.90138 11.9683 5.49312 12.2109 5.09171 12.1087C3.86916 11.7973 3.09861 11.003 2.79452 10.0146C2.50166 9.06265 2.6604 8.00271 3.11429 7.11141C3.57128 6.21401 4.35919 5.42338 5.41194 5.04584C6.48088 4.66249 7.744 4.73336 9.07859 5.42519C9.44633 5.61582 9.5899 6.06847 9.39927 6.43621C9.20864 6.80395 8.75599 6.94753 8.38825 6.75689C7.37657 6.23245 6.54689 6.23236 5.91829 6.45779C5.27351 6.68902 4.75915 7.18689 4.45095 7.79209C4.13965 8.4034 4.07028 9.06019 4.22821 9.5735Z"
        fill="currentColor"
      />
      <path
        id="Vector_5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3921 11.2051C17.8063 11.2051 18.1421 11.5409 18.1421 11.9551V12.0008C18.1421 12.415 17.8063 12.7508 17.3921 12.7508C16.9779 12.7508 16.6421 12.415 16.6421 12.0008V11.9551C16.6421 11.5409 16.9779 11.2051 17.3921 11.2051Z"
        fill="currentColor"
      />
      <path
        id="Vector_6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0307 7.57032C18.908 5.86979 15.8724 5.86979 13.7497 7.57032C13.4264 7.8293 12.9544 7.77719 12.6955 7.45392C12.4365 7.13065 12.4886 6.65865 12.8119 6.39967C15.4826 4.26001 19.2977 4.26001 21.9685 6.39967C22.2918 6.65865 22.3439 7.13065 22.0849 7.45392C21.8259 7.77719 21.3539 7.8293 21.0307 7.57032Z"
        fill="currentColor"
      />
      <path
        id="Vector_7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.003 10.0924C18.0772 9.31145 16.7059 9.31328 15.7886 10.0959C15.4734 10.3648 15 10.3273 14.7312 10.0122C14.4624 9.69704 14.4999 9.22365 14.815 8.95481C16.2943 7.69274 18.4867 7.69456 19.9702 8.9458C20.2868 9.21286 20.327 9.68604 20.0599 10.0027C19.7928 10.3193 19.3197 10.3595 19.003 10.0924Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
