import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const WarningDashboard: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9936 7.66022L17.0409 13.0765C17.3896 13.696 17.3833 14.4541 17.0245 15.0678C16.6656 15.6815 16.0081 16.0587 15.2971 16.0587H9.20359C8.49264 16.0587 7.83507 15.6815 7.47622 15.0678C7.11737 14.4541 7.11114 13.696 7.45986 13.0765L10.5071 7.66022C10.8617 7.03086 11.528 6.64148 12.2504 6.64148C12.9727 6.64148 13.639 7.03086 13.9936 7.66022Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2498 21.0037V21.0037C7.27721 21.0037 3.24609 16.9726 3.24609 12V12C3.24609 7.02733 7.27721 2.99622 12.2498 2.99622V2.99622C17.2225 2.99622 21.2536 7.02733 21.2536 12V12C21.2536 16.9726 17.2225 21.0037 12.2498 21.0037V21.0037Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2505 11.4664V9.44141"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2505 13.6017V13.5917"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
