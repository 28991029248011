import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const ImageIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Image"
      width="24"
      height="24"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.17 8.62078C13.17 7.78078 13.85 7.10078 14.69 7.10078C15.54 7.10078 16.22 7.78078 16.22 8.62078C16.22 9.46078 15.54 10.1408 14.69 10.1408C13.85 10.1408 13.17 9.46078 13.17 8.62078ZM16.41 17.5108H7.59C7.03 17.5108 6.69 16.9008 6.98 16.4208L9.67 12.0508C9.94 11.6208 10.56 11.6008 10.86 12.0108L12.54 14.3808C12.81 14.7508 13.36 14.7808 13.66 14.4208L14.27 13.7208C14.58 13.3508 15.17 13.3908 15.42 13.8108L17.02 16.4208C17.31 16.9008 16.97 17.5108 16.41 17.5108ZM16.22 2.80078H7.78C4.62 2.80078 2.5 5.03078 2.5 8.33078V16.2808C2.5 19.5808 4.62 21.8008 7.78 21.8008H16.22C19.38 21.8008 21.5 19.5808 21.5 16.2808V8.33078C21.5 5.03078 19.38 2.80078 16.22 2.80078Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
