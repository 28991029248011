import { Box, useMediaQuery, useTheme } from '@mui/material'

import CardMaintenance from './components/CardMaintenance'
import Footer from './components/Footer'
import MaintenanceIconContainer from './components/MaintenanceIconContainer'
import TimeCircleIconContainer from './components/TimeCircleIconContainer'

function MaintenanceContainer() {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box sx={{ flex: 1, display: 'flex', position: 'relative' }}>
        {isDesktop && <TimeCircleIconContainer />}
        <CardMaintenance />
        {isDesktop && <MaintenanceIconContainer />}
      </Box>

      <Box>
        <Footer />
      </Box>
    </Box>
  )
}

export default MaintenanceContainer
