import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const ThumbLikeDislike: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M8.91778 6.76469C9.63309 4.54225 9.26588 3.65699 8.55662 3.5112C8.28564 3.45549 8.17317 3.45835 7.95886 3.66706C7.2652 4.48494 6.09379 5.76528 5.45471 6.45844C5.28957 6.63755 5.207 6.72711 5.14847 6.82813C5.09401 6.92214 5.05499 7.02202 5.03131 7.12805C5.00586 7.242 5.00586 7.36316 5.00586 7.60549V10.5721C5.00586 11.2808 5.00586 11.6352 5.1416 11.9068C5.26618 12.1562 5.46834 12.3583 5.71767 12.4829C5.98932 12.6186 6.34369 12.6186 7.05241 12.6186H10.1525C10.7233 12.6186 11.0087 12.6186 11.248 12.5215C11.4683 12.432 11.6605 12.2848 11.8042 12.0954C11.9603 11.8896 12.0347 11.6141 12.1833 11.063L12.4453 10.0916C12.726 9.05095 12.8663 8.53059 12.749 8.11893C12.6417 7.74241 12.3953 7.42074 12.0597 7.21908C11.6928 6.9986 11.1539 6.9986 10.076 6.9986H9.08587C8.90076 6.9986 8.8655 6.92712 8.91778 6.76469Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M15.0803 14.667C14.365 12.4446 14.7322 11.5593 15.4414 11.4135C15.7124 11.3578 15.8249 11.3607 16.0392 11.5694C16.7329 12.3873 17.9043 13.6676 18.5433 14.3608C18.7085 14.5399 18.791 14.6295 18.8496 14.7305C18.904 14.8245 18.9431 14.9244 18.9667 15.0304C18.9922 15.1443 18.9922 15.2655 18.9922 15.5078V18.4744C18.9922 19.1832 18.9922 19.5375 18.8565 19.8092C18.7319 20.0585 18.5297 20.2607 18.2804 20.3852C18.0087 20.521 17.6544 20.521 16.9456 20.521H13.8456C13.2748 20.521 12.9894 20.521 12.75 20.4238C12.5297 20.3344 12.3376 20.1872 12.1938 19.9978C12.0377 19.792 11.9634 19.5164 11.8147 18.9653L11.5527 17.9939C11.2721 16.9533 11.1317 16.4329 11.249 16.0213C11.3564 15.6448 11.6028 15.3231 11.9383 15.1214C12.3053 14.9009 12.8442 14.9009 13.922 14.9009H14.9122C15.0973 14.9009 15.1325 14.8295 15.0803 14.667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M3 12.3985V7.47656"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M20.998 20.3009V15.3789"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_5"
        d="M19.0859 7.55859L20.0977 6.69531"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_6"
        d="M5.01562 16.8594L4.00391 17.7227"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_7"
        d="M16.5781 5.72914L17.0259 4.47681"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_8"
        d="M7.5234 18.6887L7.07562 19.941"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
