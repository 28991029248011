import { Box } from '@mui/material'
import { Calendar, Setting } from 'react-iconly'

function MaintenanceIconContainer() {
  return (
    <Box sx={{ position: 'absolute', bottom: -72, right: 0, zIndex: '-1' }}>
      <Box sx={{ position: 'relative' }}>
        <Calendar
          size={200}
          set="bulk"
          primaryColor={'rgba(132, 132, 150, 0.3)'}
          secondaryColor={'rgba(132, 132, 150, 0.3)'}
        />
        <Box
          sx={{
            position: 'absolute',
            top: -18,
            right: -24,
          }}
        >
          <Setting
            size={60}
            set="bulk"
            primaryColor={'rgba(132, 132, 150, 0.3)'}
            secondaryColor={'rgba(132, 132, 150, 0.3)'}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default MaintenanceIconContainer
