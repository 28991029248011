declare module '@mui/system' {
  interface Shape {
    borderRadius: number
    borderRadiusL: string | number
    borderRadiusXL: string | number
    borderRadiusXXL: string | number
  }
}

const baseRadius = 4

export const shape = {
  borderRadius: baseRadius,
  borderRadiusL: `${baseRadius * 1.5}px`, // 4 * 1,5 = 6px
  borderRadiusXL: `${baseRadius * 2}px`, // 4 * 2 = 8px
  borderRadiusXXL: `${baseRadius * 3}px`, // 4 * 2 = 12px
}
