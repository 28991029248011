import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Wheelchair: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width="72px"
      height="72px"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.9902 3.89737C4.97468 2.84117 6.37669 2.25 8.03216 2.25H16.4669C18.1262 2.25 19.5288 2.84084 20.5129 3.89765C21.4913 4.94841 22 6.39889 22 8.02638V15.9736C22 17.6011 21.4913 19.0516 20.5127 20.1024C19.5285 21.1592 18.1256 21.75 16.4659 21.75H8.03216C6.3728 21.75 4.97038 21.1591 3.98657 20.1023C3.00842 19.0515 2.5 17.601 2.5 15.9736V8.02638C2.5 6.39806 3.01117 4.94771 3.9902 3.89737ZM5.08745 4.92012C4.41024 5.64666 4 6.70951 4 8.02638V15.9736C4 17.2914 4.40861 18.3541 5.08451 19.0802C5.75475 19.8003 6.74341 20.25 8.03216 20.25H16.4659C17.7552 20.25 18.7444 19.8002 19.415 19.0801C20.0912 18.354 20.5 17.2913 20.5 15.9736V8.02638C20.5 6.70868 20.0912 5.64597 19.4151 4.91984C18.7446 4.19975 17.7556 3.75 16.4669 3.75H8.03216C6.74828 3.75 5.75921 4.19943 5.08745 4.92012Z"
        fill="#000000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8414 11.0117C11.6712 11.0117 11.5151 11.0267 11.3774 11.0577C11.3653 11.0604 11.353 11.0628 11.3407 11.065C10.0419 11.2896 9.05273 12.4232 9.05273 13.7994C9.05273 15.3327 10.3034 16.5794 11.8414 16.5794C12.8154 16.5794 13.6679 16.0867 14.1652 15.3395L14.1695 15.3331L14.1695 15.3331C14.4909 14.8609 14.7096 14.4682 14.8113 14.1617C14.9417 13.7685 15.3662 13.5556 15.7593 13.686C16.1525 13.8164 16.3654 14.2408 16.235 14.634C16.0665 15.1418 15.753 15.6723 15.4116 16.1741C14.6458 17.3223 13.3335 18.0794 11.8414 18.0794C9.47981 18.0794 7.55273 16.1659 7.55273 13.7994C7.55273 11.691 9.06664 9.94489 11.0671 9.59006C11.3299 9.53269 11.5933 9.51172 11.8414 9.51172H13.9479C14.3621 9.51172 14.6979 9.84751 14.6979 10.2617C14.6979 10.6759 14.3621 11.0117 13.9479 11.0117H11.8414Z"
        fill="#000000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2722 8.56915C11.6828 8.51448 12.0599 8.803 12.1146 9.21359L12.5699 12.6325H14.7591C14.9945 12.6325 15.2163 12.7431 15.358 12.9311L17.4042 15.6457C17.6535 15.9765 17.5874 16.4467 17.2567 16.696C16.9259 16.9454 16.4556 16.8793 16.2063 16.5486L14.3852 14.1325H11.9131C11.5372 14.1325 11.2193 13.8542 11.1697 13.4815L10.6277 9.41158C10.5731 9.00099 10.8616 8.62382 11.2722 8.56915Z"
        fill="#000000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.88086 6.87224C9.88086 6.19636 10.4288 5.64844 11.1047 5.64844C11.7806 5.64844 12.3285 6.19635 12.3285 6.87224C12.3285 7.54813 11.7806 8.09605 11.1047 8.09605C10.4288 8.09605 9.88086 7.54813 9.88086 6.87224Z"
        fill="#000000"
      />
    </svg>
  </SvgIcon>
)
