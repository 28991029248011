import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const CameraRemove: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9866 3.80469L3.37988 20.3163"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9298 7.35156C20.5817 7.89644 21.0001 8.72348 21.0001 9.65755V15.3009C21.0001 17.568 19.1611 19.4069 16.8941 19.4069H8.52637"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.86814 18.7396C3.73947 18.0099 3 16.745 3 15.305V9.66166C3 7.97838 4.37192 6.60647 6.06492 6.60647C6.55141 6.58701 7.01845 6.38268 7.35899 6.02267C7.53413 5.81835 7.83576 5.32212 8.09846 4.84536C8.49739 4.13508 9.23686 3.6875 10.0542 3.6875H13.9656C14.7926 3.6875 15.5418 4.14481 15.931 4.87455C16.184 5.34158 16.4662 5.82808 16.6413 6.02267C16.6802 6.07132 16.7289 6.11024 16.7775 6.13943"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25653 14.0966C9.03274 13.6685 8.90625 13.1723 8.90625 12.6566C8.90625 10.9539 10.2879 9.5625 12.0004 9.5625C12.4577 9.5625 12.8858 9.6598 13.275 9.84467"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0918 12.6562C15.0918 14.3643 13.7071 15.749 11.999 15.749"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
