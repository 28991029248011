import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const QuestionMarkSquare: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M7.78216 3H16.2169C19.165 3 21 5.08119 21 8.02638V15.9736C21 18.9188 19.165 21 16.2159 21H7.78216C4.83405 21 3 18.9188 3 15.9736V8.02638C3 5.08119 4.84281 3 7.78216 3Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M11.7383 16.5273V16.5711"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M11.7375 13.8913C11.7249 12.9291 12.6006 12.5204 13.2525 12.1487C14.0454 11.7109 14.5845 11.0133 14.5845 10.0471C14.5845 8.61393 13.4257 7.46484 12.0022 7.46484C10.57 7.46484 9.41992 8.61393 9.41992 10.0471"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
