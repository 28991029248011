import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const FormatUnLink: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Link hidden"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.265 21.0026C7.626 21.0026 6.036 20.3646 4.836 19.1676C3.651 17.9816 2.999 16.4006 3 14.7156C3.001 13.0306 3.654 11.4536 4.835 10.2726L5.864 9.25062C6.255 8.85962 6.888 8.86262 7.278 9.25462C7.667 9.64662 7.665 10.2786 7.274 10.6686L6.247 11.6896C5.445 12.4906 5.001 13.5666 5 14.7166C5 15.8676 5.444 16.9456 6.249 17.7526C7.388 18.8886 9.12 19.2886 10.669 18.7716C11.193 18.5966 11.759 18.8776 11.935 19.4016C12.111 19.9256 11.828 20.4916 11.304 20.6676C10.632 20.8926 9.945 21.0026 9.265 21.0026Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.432 15.0498C17.177 15.0498 16.921 14.9528 16.726 14.7578C16.335 14.3678 16.333 13.7358 16.724 13.3438L17.75 12.3138C18.556 11.5088 18.999 10.4328 19 9.28276C19 8.13176 18.557 7.05376 17.751 6.24676C16.612 5.11076 14.878 4.70976 13.331 5.22876C12.809 5.40376 12.243 5.12276 12.066 4.59776C11.89 4.07376 12.172 3.50776 12.697 3.33176C14.988 2.56676 17.467 3.13776 19.164 4.83176C20.35 6.01776 21.001 7.59876 21 9.28376C20.999 10.9688 20.347 12.5458 19.165 13.7278L18.14 14.7558C17.945 14.9518 17.689 15.0498 17.432 15.0498Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9828 20.3705C14.5958 20.3705 14.2278 20.1445 14.0648 19.7665L7.87076 5.4065C7.65176 4.8985 7.88476 4.3105 8.39176 4.0925C8.90276 3.8725 9.48776 4.1065 9.70676 4.6135L15.9008 18.9745C16.1198 19.4815 15.8858 20.0695 15.3788 20.2885C15.2498 20.3445 15.1158 20.3705 14.9828 20.3705Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
