import {
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  ThemeOptions,
  ThemeProvider,
} from '@mui/material'
import { FC } from 'react'

import { breakpoints } from './breakpoints'
import { componentsOverrides } from './overrides/index'
import { palette } from './palette'
import { customShadows } from './shadows'
import { shape } from './shape'
import { typography } from './typography'

export const ThemeConfig: FC<{ children: React.ReactNode }> = ({ children }) => {
  const themeOptions: ThemeOptions = {
    palette,
    shape,
    typography,
    breakpoints,
    customShadows: customShadows.light,
  }

  const theme = createTheme(themeOptions)
  theme.components = componentsOverrides(theme)

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  )
}
