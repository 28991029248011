import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const SparkOrange: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2198 0L9.60282 8.98176L7.46675 4.86383L5.99352 5.58608L8.19811 9.83682L0.852742 5.52787L0 6.90187L7.26 11.1606H1.23561V12.7654H7.64708L2.60407 16.2627L3.56276 17.5692L8.64787 14.0421L7.35496 17.8389L8.92198 18.3435L10.5837 13.4617L15.2242 18.0488L16.4009 16.9231L12.1943 12.7654H13.1901H18.486V11.1606H12.227L17.5591 6.01202L16.396 4.87361L11.1942 9.89663L11.8666 0.106884L10.2198 0Z"
        fill="#FD7752"
      />
    </svg>
  </SvgIcon>
)
