import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const MacLaptopNotebook: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0243 3.98633C18.37 3.98633 19.4597 5.07605 19.4597 6.42167V14.4265L20.8856 17.5295C21.3273 18.735 20.4351 20.0125 19.1508 20.0125H4.84913C3.56579 20.0125 2.67261 18.736 3.11433 17.5295L4.54117 14.4265V6.42167C4.54117 5.07605 5.63186 3.98633 6.97651 3.98633H17.0243Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6934 17.2539H13.3087"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4431 14.4277H4.55859"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
