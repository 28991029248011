import { i18n } from './i18nConfig'

export function getLabelFromGroup(group: string) {
  switch (group) {
    case 'low':
      return i18n.t('activity.exotic.group.low', 'Low')
    case 'middle':
      return i18n.t('activity.exotic.group.medium', 'Medium')
    case 'high':
      return i18n.t('activity.exotic.group.high', 'High')
  }
  return ''
}
