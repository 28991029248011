import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const ShieldLoad: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00586 18.6037C4.00586 19.6897 4.88684 20.5697 5.97284 20.5697C7.05784 20.5697 7.93884 19.6897 7.93884 18.6037C7.93884 17.5177 7.05784 16.6367 5.97284 16.6367C4.88684 16.6367 4.00586 17.5177 4.00586 18.6037Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6465 5.96307C16.6465 7.04907 17.5265 7.92908 18.6125 7.92908C19.6985 7.92908 20.5795 7.04907 20.5795 5.96307C20.5795 4.87707 19.6985 3.99609 18.6125 3.99609C17.5265 3.99609 16.6465 4.87707 16.6465 5.96307Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M14.9494 12.5718C14.9494 14.8238 12.2504 15.6408 12.2504 15.6408C12.2504 15.6408 9.55136 14.8248 9.55136 12.5718C9.55136 10.3178 9.45338 10.1418 9.67038 9.92484C9.88738 9.70884 11.8964 9.00781 12.2504 9.00781C12.6044 9.00781 14.8304 9.92484 14.8304 9.92484"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M12.254 3.32422C14.033 3.32422 15.691 3.84321 17.08 4.73221"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_5"
        d="M3.25 12.3106C3.25659 8.6704 5.41393 5.54349 8.51992 4.12891"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_6"
        d="M21.2497 12.3203C21.2497 16.1131 18.9097 19.3579 15.5928 20.6845"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_7"
        d="M12.2537 21.325C10.4757 21.325 8.81773 20.807 7.42773 19.918"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
