import React from 'react'

import { typography as typographyTokens } from '../system'

// Custom variants declaration (mui@4)
declare module '@mui/material/styles' {
  interface TypographyVariants {
    body1Bold: React.CSSProperties
    body2Bold: React.CSSProperties
    body3: React.CSSProperties
    body3Bold: React.CSSProperties
    body4: React.CSSProperties
    body4Bold: React.CSSProperties
    body5: React.CSSProperties
    body5Bold: React.CSSProperties
    h6?: React.CSSProperties
    links1?: React.CSSProperties
    button2?: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body1Bold: React.CSSProperties
    body2Bold: React.CSSProperties
    body3: React.CSSProperties
    body3Bold: React.CSSProperties
    body4: React.CSSProperties
    body4Bold: React.CSSProperties
    body5: React.CSSProperties
    body5Bold: React.CSSProperties
    h6?: React.CSSProperties
    links1?: React.CSSProperties
    button2?: React.CSSProperties
  }
}

// Update the Typography's variant prop options (mui@4)
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1Bold: true
    body2Bold: true
    body3: true
    body3Bold: true
    body4: true
    body4Bold: true
    body5: true
    body5Bold: true
    h6: true
    links1: true
    button2: true
  }
}

// Update the Typography's variant prop options (mui@5)
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1Bold: true
    body2Bold: true
    body3: true
    body3Bold: true
    body4: true
    body4Bold: true
    body5: true
    body5Bold: true
    h6: true
    links1: true
    button2: true
  }
}

export const typography = {
  body1: {
    ...typographyTokens.body1,
    lineHeight: 1.2,
  },
  body1Bold: typographyTokens.body1Bold,
  body2: typographyTokens.body2,
  body2Bold: typographyTokens.body2Bold,
  body3: typographyTokens.body3,
  body3Bold: typographyTokens.body3Bold,
  body4: typographyTokens.body4,
  body4Bold: typographyTokens.body4Bold,
  body5: typographyTokens.body5,
  body5Bold: typographyTokens.body5Bold,
  h1: typographyTokens.h1,
  h2: typographyTokens.h2,
  h3: typographyTokens.h3,
  h4: typographyTokens.h4,
  h5: typographyTokens.h5,
  h6: {
    ...typographyTokens.h6,
    lineHeight: 1.2,
  },
  links1: typographyTokens.links1,
  button: typographyTokens.button,
  button2: typographyTokens.button2,
  caption: typographyTokens.caption1,
  fontFamily: "'Inter', sans-serif",
}
