import { Components, Theme } from '@mui/material'

export const Card = (theme: Theme): Components<Theme> => ({
  MuiCard: {
    defaultProps: {
      elevation: 1,
    },
    styleOverrides: {
      root: {
        position: 'relative',
        zIndex: 0, // Fix Safari overflow: hidden with border radius
        padding: 0,
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: { variant: 'h6' },
      subheaderTypographyProps: { variant: 'body2' },
    },
    styleOverrides: {
      root: {
        padding: theme.spacing(3, 3, 0),
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: 0,
        margin: 0,
      },
    },
  },
})
