import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Messages: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6033 18.0262C16.6909 17.5341 20.0349 14.5978 19.6014 10.1899C19.2446 6.55904 16.3416 3.55799 12.7276 3.15123C7.96474 2.6142 4.20321 6.31911 3.9865 10.9519C3.77446 15.5041 7.46407 19.2577 11.5488 20.4199C11.858 20.508 12.1635 20.2605 12.1635 19.9381V18.5107C12.1635 18.2596 12.3539 18.0562 12.6033 18.0262Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.44"
      />
      <path
        d="M9.17059 13.0498H11.83M9.17059 9.37134H14.4462"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.44"
      />
    </svg>
  </SvgIcon>
)
