import { GraphQLClient } from 'graphql-request'
import * as Dom from 'graphql-request/dist/types.dom'

// Custom class to handle headers only at one place
// We can't get them once it's set in GraphQLClient otherwise
export class CustomGraphQLClient extends GraphQLClient {
  private headers: { [key: string]: string }
  constructor(url: string, options?: Dom.RequestInit) {
    super(url, options)
    this.headers = {}
  }

  setHeader(key: string, value: string): GraphQLClient {
    this.headers[key] = value
    super.setHeader(key, value)
    return this
  }

  getHeaders(): { [key: string]: string } {
    return { ...this.headers }
  }
}
