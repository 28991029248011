import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Quote: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Quotation open"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.67416 9.71336C8.05216 8.84136 8.54516 8.02336 9.14716 7.27236C9.23316 7.16436 9.27116 7.02536 9.25116 6.88836C9.23216 6.75136 9.15616 6.62936 9.04316 6.55036C8.54216 6.19836 8.03916 5.85736 7.53516 5.51536C7.36216 5.39836 7.13616 5.40136 6.96316 5.52336C4.93216 6.98136 3.45316 9.11036 2.80016 11.5184C2.47916 12.6954 2.12816 14.9374 3.38816 16.7284C4.20816 17.8844 5.62916 18.5704 7.03416 18.5704C7.40816 18.5704 7.78116 18.5224 8.14216 18.4214C9.57916 18.0254 10.6962 16.9134 11.1292 15.4464C11.5792 13.9204 11.2312 12.3174 10.1972 11.1574C9.53816 10.4144 8.64416 9.90736 7.67416 9.71336Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3772 11.158C19.7182 10.415 18.8232 9.90799 17.8542 9.71399C18.2322 8.83999 18.7272 8.02099 19.3262 7.27199C19.4132 7.16499 19.4512 7.02599 19.4322 6.88799C19.4122 6.75199 19.3362 6.62999 19.2232 6.54999C18.7212 6.19899 18.2192 5.85599 17.7152 5.51499C17.5412 5.39799 17.3142 5.40199 17.1432 5.52299C15.1112 6.98099 13.6332 9.11099 12.9802 11.518C12.6582 12.697 12.3092 14.941 13.5662 16.728C14.3872 17.885 15.8082 18.571 17.2142 18.571C17.5882 18.571 17.9622 18.522 18.3222 18.422C19.7592 18.026 20.8762 16.915 21.3092 15.449C21.7592 13.923 21.4112 12.318 20.3772 11.158Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
