import { useMemo } from 'react'

export function useNonNullableArrayData<T>(data: T[] | undefined) {
  return useMemo(() => {
    if (!data) {
      return []
    }
    return data.filter((item): item is NonNullable<T> => !!item)
  }, [data])
}
