import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const SparkBlue: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.739463 28.137C15.0368 16.5216 15.1386 15.7965 4.52735 0.739467C16.1427 15.0368 16.856 15.1425 31.9249 4.52735C17.6275 16.1427 17.5219 16.856 28.137 31.9249C16.5178 17.6157 15.7965 17.5257 0.739463 28.137Z"
        fill="#3D85FF"
      />
    </svg>
  </SvgIcon>
)
